import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { LoadingAnimation } from '../../central-ui/components/Container/MainWindow/LoadingAnimation/LoadingAnimation';

const OfferPage = () => {
    const history = useHistory();
    const host = window.location.host;

    const { benit_homepage_get_objects } = useActions();

    const { objectEntries } = useTypedSelector((state) => state.riReducer);

    useEffect(() => {
        benit_homepage_get_objects();
    }, []);

    const currency = (number: number) => {
        return new Intl.NumberFormat('de-de', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        }).format(number);
    };

    return (
        <>
            <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                <span className="text-cap">Aktuelle Immobilien-Angebote</span>
                <h2>Häuser, Wohnungen und Grundstücke</h2>
            </div>
            {!objectEntries ? <LoadingAnimation /> : null}
            <div className={`${!objectEntries ? 'd-none' : null}`}>
                <div className="container mt-sm-7">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 mb-5">
                        {objectEntries
                            ? objectEntries.map((entry: any) => {
                                  return (
                                      <div className="col mb-3 mr-3">
                                          {/* Card */}
                                          <Link
                                              to={`/angebote/${entry.data.externalId}`}
                                              className={`card card-flush shadow-none h-100`}
                                              onClick={(e) => history.push(`/angebote/${entry.data.externalId}`)}
                                          >
                                              {entry.data.pictures && entry.data.pictures[Object.keys(entry.data.pictures)[0]] ? (
                                                  <>
                                                      <div className="card-pinned">
                                                          <div className="d-flex justify-content-center">
                                                              <img
                                                                  className="card-img"
                                                                  src={`https://${
                                                                      host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de'
                                                                          ? 'benit.cloud'
                                                                          : 'benit.dev'
                                                                  }/api/ri/real-estate/objekt/files/${entry.data.pictures[Object.keys(entry.data.pictures)[0]].path}`}
                                                                  alt="Image Description"
                                                                  style={{}}
                                                              />
                                                              {/* <div className="card-pinned-top-start">
                                                              <span className="badge bg-success">Neu</span>
                                                          </div> */}
                                                          </div>
                                                      </div>
                                                      {entry.data.realEstateStatus && entry.data.realEstateStatus === 'RESERVED' ? (
                                                          <div className="card-pinned-top-start">
                                                              <span className="h3">
                                                                  <span className="badge bg-secondary">reserviert</span>
                                                              </span>
                                                          </div>
                                                      ) : null}
                                                      {entry.data.realEstateStatus && (entry.data.realEstateStatus === 'SOLD' || entry.data.realEstateStatus === 'RENTED') ? (
                                                          <div className="card-pinned-top-start">
                                                              <span className="h3">
                                                                  <span className="badge badge-lg bg-danger">{entry.data.realEstateStatus === 'SOLD' ? 'verkauft' : 'vermietet'}</span>
                                                              </span>
                                                          </div>
                                                      ) : null}
                                                  </>
                                              ) : null}
                                              {/* Body */}
                                              <div className="card-body">
                                                  <span className="card-subtitle text-body">{entry.data.realEstateType_text}</span>
                                                  <div className="row align-items-center mb-3">
                                                      <div className="col">
                                                          <h4 className="card-title text-inherit">{entry.data.title}</h4>
                                                      </div>
                                                      {/* End Col */}
                                                  </div>
                                                  <div className="row align-items-center mb-3">
                                                      <div className="col-auto">
                                                          <h3 className="card-title text-primary">
                                                              {entry.data.realEstateType === 'realestates.houseBuy' ? currency(entry.data.value) : null}
                                                              {entry.data.realEstateType === 'realestates.houseRent' ? `${currency(entry.data.baseRent)} / Monat (kalt)` : null}
                                                              {entry.data.realEstateType === 'realestates.apartmentBuy' ? currency(entry.data.value) : null}
                                                              {entry.data.realEstateType === 'realestates.apartmentRent' ? `${currency(entry.data.baseRent)} / Monat (kalt)` : null}
                                                              {entry.data.realEstateType === 'realestates.livingBuySite' ? currency(entry.data.value) : null}
                                                              {entry.data.realEstateType === 'realestates.office' ? `${currency(entry.data.value)} / Monat (kalt)` : null}
                                                              {entry.data.realEstateType === 'realestates.investment' ? currency(entry.data.value) : null}
                                                              {entry.data.realEstateType === 'realestates.garageBuy' ? currency(entry.data.value) : null}
                                                              {entry.data.realEstateType === 'realestates.garageRent' ? `${currency(entry.data.value)} / Monat` : null}
                                                              {entry.data.realEstateType === 'realestates.store' ? `${currency(entry.data.value)} / Monat (kalt)` : null}
                                                              {entry.data.realEstateType === 'realestates.tradeSite' ? currency(entry.data.value) : null}
                                                          </h3>
                                                      </div>
                                                      {/* End Col */}
                                                  </div>
                                                  {/* End Row */}
                                                  <ul className="list-inline list-separator text-body small">
                                                      {entry.data.realEstateType === 'realestates.houseBuy' ||
                                                      entry.data.realEstateType === 'realestates.houseRent' ||
                                                      entry.data.realEstateType === 'realestates.apartmentBuy' ||
                                                      entry.data.realEstateType === 'realestates.apartmentRent' ? (
                                                          <>
                                                              <li className="list-inline-item">
                                                                  <span className="svg-icon svg-icon-xs svg-inline text-muted me-1">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 640 512">
                                                                          <path d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z" />
                                                                      </svg>
                                                                  </span>
                                                                  {entry.data.numberOfBedRooms} Schlafzimmer
                                                              </li>
                                                              <li className="list-inline-item">
                                                                  <span className="svg-icon svg-icon-xs svg-inline text-muted me-1">
                                                                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                                                                          <path d="M304,320a16,16,0,1,0,16,16A16,16,0,0,0,304,320Zm32-96a16,16,0,1,0,16,16A16,16,0,0,0,336,224Zm32,64a16,16,0,1,0-16-16A16,16,0,0,0,368,288Zm-32,32a16,16,0,1,0-16-16A16,16,0,0,0,336,320Zm-32-64a16,16,0,1,0,16,16A16,16,0,0,0,304,256Zm128-32a16,16,0,1,0-16-16A16,16,0,0,0,432,224Zm-48,16a16,16,0,1,0,16-16A16,16,0,0,0,384,240Zm-16-48a16,16,0,1,0,16,16A16,16,0,0,0,368,192Zm96,32a16,16,0,1,0,16,16A16,16,0,0,0,464,224Zm32-32a16,16,0,1,0,16,16A16,16,0,0,0,496,192Zm-64,64a16,16,0,1,0,16,16A16,16,0,0,0,432,256Zm-32,32a16,16,0,1,0,16,16A16,16,0,0,0,400,288Zm-64,64a16,16,0,1,0,16,16A16,16,0,0,0,336,352Zm-32,32a16,16,0,1,0,16,16A16,16,0,0,0,304,384Zm64-64a16,16,0,1,0,16,16A16,16,0,0,0,368,320Zm21.65-218.35-11.3-11.31a16,16,0,0,0-22.63,0L350.05,96A111.19,111.19,0,0,0,272,64c-19.24,0-37.08,5.3-52.9,13.85l-10-10A121.72,121.72,0,0,0,123.44,32C55.49,31.5,0,92.91,0,160.85V464a16,16,0,0,0,16,16H48a16,16,0,0,0,16-16V158.4c0-30.15,21-58.2,51-61.93a58.38,58.38,0,0,1,48.93,16.67l10,10C165.3,138.92,160,156.76,160,176a111.23,111.23,0,0,0,32,78.05l-5.66,5.67a16,16,0,0,0,0,22.62l11.3,11.31a16,16,0,0,0,22.63,0L389.65,124.28A16,16,0,0,0,389.65,101.65Z" />
                                                                      </svg>
                                                                  </span>
                                                                  {entry.data.numberOfBathRooms} {entry.data.numberOfBathRooms > 1 ? 'Bäder' : 'Bad'}
                                                              </li>
                                                          </>
                                                      ) : null}
                                                      {entry.data.livingSpace ? (
                                                          <li className="list-inline-item">
                                                              <i className="bi-rulers text-muted me-1" />
                                                              {Intl.NumberFormat('de-de', {
                                                                  minimumFractionDigits: 0,
                                                              }).format(entry.data.livingSpace)}{' '}
                                                              ㎡
                                                          </li>
                                                      ) : null}
                                                  </ul>
                                              </div>
                                              {/* End Body */}
                                          </Link>
                                          {/* End Card */}
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                    {/* End Row */}
                </div>
            </div>
        </>
    );
};

export default OfferPage;

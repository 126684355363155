import styles from './LoadingStyle.module.css';

export const LoadingAnimation = () => {
    return (
        <div className={styles.centerPosition}>
            <div className={styles.loading}>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

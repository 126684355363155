import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';

interface BenitLandingPageState {
    objectEntries: any | null;
    detailEntry: any | null;
    detailEntryExists: boolean;
    passcodeLength: number | null;
    passcodeCorrect: boolean | null;
    requestSuccessful: boolean;
    valuationRequestSuccessful: boolean;
}

const initialState = {
    objectEntries: null,
    detailEntry: null,
    detailEntryExists: false,
    passcodeLength: null,
    passcodeCorrect: null,
    requestSuccessful: false,
    valuationRequestSuccessful: false,
};

const reducer = (state: BenitLandingPageState = initialState, action: Action): BenitLandingPageState => {
    switch (action.type) {
        case ActionType.RI_HOMEPAGE_OBJECTS_GET_SUCCESS:
            return {
                ...state,
                objectEntries: action.payload.objekte,
                detailEntry: null,
                detailEntryExists: false,
            };
        case ActionType.RI_HOMEPAGE_DETAIL_ENTRY_GET_SUCCESS:
            return {
                ...state,
                detailEntry: action.payload.objekte[0] && action.payload.objekte[0].data ? action.payload.objekte[0].data : null,
                detailEntryExists: true,
                passcodeLength: action.payload.passcodeLength,
                passcodeCorrect: action.payload.passcodeCorrect,
                requestSuccessful: false,
            };
        case ActionType.RI_HOMEPAGE_REQUEST_SUCCESS:
            return {
                ...state,
                requestSuccessful: true,
            };
        case ActionType.RI_HOMEPAGE_VALUATION_REQUEST_SUCCESS:
            return {
                ...state,
                valuationRequestSuccessful: true,
            };
        default:
            return state;
    }
};

export default reducer;

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import fireToast from '../../central-ui/methods/fireToast';
// @ts-ignore
import FsLightbox from 'fslightbox-react';
import { LoadingAnimation } from '../../central-ui/components/Container/MainWindow/LoadingAnimation/LoadingAnimation';
import './DetailPage.css';

interface IProps {
    itemId: string;
    passcode: string;
}

const AppPage = ({ itemId, passcode }: IProps) => {
    const { benit_homepage_get_detail_entry } = useActions();
    const { detailEntry } = useTypedSelector((state) => state.riReducer);

    useEffect(() => {
        benit_homepage_get_detail_entry(itemId, passcode, passcode);
    }, []);

    return (
        <div className="row mb-4">
            <div className="col-xl-4 offset-xl-4">
                <div className="card m-2">
                    <div className="card-header">
                        <h3 className="card-title text-center">Privater Bereich - {detailEntry && detailEntry.owner ? detailEntry.owner : null}</h3>
                    </div>
                    <div className="card-body pt-5">
                        <div className="col-12 text-center mb-3">
                            Guten Tag {detailEntry && detailEntry.owner ? detailEntry.owner : null}, <br />
                            bitte installieren sie die Rauprecht Immobilien App via Android Play Store / Apple App Store
                        </div>
                        <div className="row">
                            <div className="col-xl-6 text-center mb-3">
                                <a className="btn btn-outline-secondary btn-block" style={{ display: 'block' }} href={`#`} role="button">
                                    <i className="fab fa-google-play mr-2"></i> Play Store
                                </a>
                            </div>
                            <div className="col-xl-6 text-center mb-3">
                                <a className="btn btn-outline-secondary btn-block" style={{ display: 'block' }} href={`#`} role="button">
                                    <i className="fab fa-apple"></i> App Store
                                </a>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                Sobald die App auf Ihrem Handy installiert ist, können Sie die Interessenten für Ihr Objekt mit einem Klick auf den unteren Button in der App anzeigen lassen.
                            </div>
                        </div>
                        <div className="row">
                            <a className="btn btn-outline-primary" style={{ display: 'block' }} href={`rauprechtImmobilien://angebote/object-detail/${itemId}/${passcode}`} role="button">
                                Objekt-Interessenten in RI App anzeigen
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppPage;

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const LandingPage = () => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // @ts-ignore
        var swiper = new Swiper('.js-swiper-gallery-2', {
            autoplay: {
                delay: 5000,
            },
            loop: true,
            slidesPerView: 1,
            pagination: {
                el: '.js-swiper-gallery-2-pagination',
                dynamicBullets: true,
                clickable: true,
            },
            navigation: {
                nextEl: '.js-swiper-slides-per-view-button-next',
                prevEl: '.js-swiper-slides-per-view-button-prev',
            },
        });

        // INITIALIZATION OF TEXT ANIMATION (TYPING)
        // =======================================================
        // @ts-ignore
        HSCore.components.HSTyped.init('.js-typedjs');
    }, []);

    return (
        <>
            <div>
                {/* Hero */}

                <div className="container">
                    {/* Heading */}
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-4">
                        <h3>Herzlich Willkommen auf der Internetpräsenz der Rauprecht Immobilien GmbH</h3>
                    </div>
                    {/* End Heading */}
                    <div className="row mb-4">
                        <div className="col-sm-6 col-md-6 mb-5 mb-lg-0">
                            {/* Team */}
                            <img className="card-img rounded-2" src={`${'../img/template-pictures/KatrinRauprecht4.jpeg'}`} alt="Image Description" />
                            <div className="card card-sm text-center mt-n7 mx-3">
                                <div className="card-body">
                                    <h4 className="card-title">Katrin Rauprecht</h4>
                                    <p className="card-text">Geschäftsführerin, Immobilienfachwirtin</p>
                                </div>
                            </div>
                            {/* End Team */}
                        </div>
                        {/* End Col */}
                        <div className="col-sm-6 col-md-6 mb-5 mb-lg-0">
                            {/* Team */}
                            <img className="card-img rounded-2" src={`${'../img/template-pictures/UweRauprecht2.png'}`} alt="Image Description" />
                            <div className="card card-sm text-center mt-n7 mx-3">
                                <div className="card-body">
                                    <h4 className="card-title">Uwe Rauprecht</h4>
                                    <p className="card-text">Geschäftsführer, Diplom- Ingenieur</p>
                                </div>
                            </div>
                            {/* End Team */}
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>
                {/* End Team */}

                {/* <div className="container-fluid">
                    <div className="js-swiper-gallery-2 swiper">
                        <div className="swiper-wrapper">
                            <div
                                className="swiper-slide bg-img-center content-space-3 content-space-md-5 rounded-2"
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/template-pictures/AdobeStock_166811045_dark.jpeg)` }}
                            >
                                <div className="container">
                                    <div className="w-lg-30 text-center mx-lg-auto">
                                        <div className="mb-5">
                                            <h4 className="display-4 text-white mb-3">Immobilie verkaufen</h4>
                                            <p className="text-white">Mit uns verkaufen Sie Ihre Immobilie schnell und zu marktgerechten Preisen.</p>
                                        </div>
                                        <Link to="/verkauf" className={`btn btn-light btn-transition`} onClick={(e) => history.push('/verkauf')}>
                                            Zu den Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="swiper-slide bg-img-center content-space-3 content-space-md-5 rounded-2"
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/template-pictures/AdobeStock_194460411_dark.jpeg)` }}
                            >
                                <div className="container">
                                    <div className="w-lg-30 text-center mx-lg-auto">
                                        <div className="mb-5">
                                            <h4 className="display-4 text-white mb-3">Immobilie bewerten</h4>
                                            <p className="text-white">Sie wollen wissen, wieviel Ihre Immobilie wert ist und welchen Preis Sie aktuell am Markt erzielen können?</p>
                                        </div>
                                        <Link to="/immobilienbewertung" className={`btn btn-light btn-transition`} onClick={(e) => history.push('/immobilienbewertung')}>
                                            Zu den Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="swiper-slide bg-img-center content-space-3 content-space-md-5 rounded-2"
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/template-pictures/AdobeStock_281395932_dark.jpeg)` }}
                            >
                                <div className="container">
                                    <div className="w-lg-30 text-center mx-lg-auto">
                                        <div className="mb-5">
                                            <h4 className="display-4 text-white mb-3">Immobilien suchen</h4>
                                            <p className="text-white">
                                                Sie suchen eine Immobilie? <br />
                                                Schauen Sie sich hier unsere aktuellen Angebote an.
                                            </p>
                                        </div>
                                        <Link to="/angebote" className={`btn btn-light btn-transition`} onClick={(e) => history.push('/angebote')}>
                                            Zu den aktuellen Angeboten
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="swiper-slide bg-img-center content-space-3 content-space-md-5 rounded-2"
                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/template-pictures/AdobeStock_225809723_dark.jpeg)` }}
                            >
                                <div className="container">
                                    <div className="w-lg-30 text-center mx-lg-auto">
                                        <div className="mb-5">
                                            <h4 className="display-4 text-white mb-3">Immobilie verwalten</h4>
                                            <p className="text-white">Sie haben eine Immobilie - Wir kümmern uns um die Verwaltung für Sie.</p>
                                        </div>
                                        <Link to="/verwaltung" className={`btn btn-light btn-transition`} onClick={(e) => history.push('/verwaltung')}>
                                            Zu den Details
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="js-swiper-gallery-2-pagination swiper-pagination swiper-pagination-light position-absolute bottom-0 start-0 end-0 mb-5"></div>
                    </div>
                </div> */}

                {/* <div className="container">
                    <div className="card card-stretched-vertical">
                        <div className="card-body">
                            <div className="row col-md-divider align-items-md-center">
                                <div className="col-md-12">
                                    <h3>Unsere Dienstleistungen</h3>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <ul className="list-checked list-checked-bg-primary list-checked-sm mb-0">
                                                <li className="list-checked-item">Vermittlung von Neubauprojekten mit Firmen der Region</li>
                                                <li className="list-checked-item">Beratung von Erbengemeinschaften bei Grundstücksverkäufen</li>
                                                <li className="list-checked-item">Immobilienverwaltung</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-6">
                                            <ul className="list-checked list-checked-bg-primary list-checked-sm mb-0">
                                                <li className="list-checked-item">Marktwertermittlung auf Sprengnetter-Datenbasis und jahrelangen Markterfahrungen</li>
                                                <li className="list-checked-item">Energieausweiserstellung</li>
                                                <li className="list-checked-item">Immobilienbewertung für EFH</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="container content-space-1 content-space-lg-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-4">
                        <h3>Unsere Dienstleistungen</h3>
                    </div>
                    <div className="overflow-hidden">
                        <div className="row justify-content-md-center">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <Link to="/angebote" className={`card card-transition bg-soft-primary shadow-none h-100`} onClick={(e) => history.push('/angebote')}>
                                    <div className="card-body">
                                        <div className="mb-5">
                                            <img className="img-fluid rounded-2" src="../img/template-pictures/Card_1.jpeg" alt="Image Description" />
                                        </div>

                                        <h3 className="card-title text-inherit">Angebote</h3>
                                        <span className="d-block text-body mb-3">Für Käufer und Mieter</span>
                                        <p className="card-text text-dark">Wir halten ein interessantes und stets aktuelles Immobilienangebot überwiegend in Berlin und Brandenburg für Sie bereit.</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <Link to="/verkauf" className={`card card-transition bg-soft-primary shadow-none h-100`} onClick={(e) => history.push('/verkauf')}>
                                    <div className="card-body">
                                        <div className="mb-5">
                                            <img className="img-fluid rounded-2" src="../img/template-pictures/Card_2.jpeg" alt="Image Description" />
                                        </div>

                                        <h3 className="card-title text-inherit mb-1">Vermittlung</h3>
                                        <span className="d-block text-body mb-3">Für Verkäufer und Vermieter</span>
                                        <p className="card-text text-dark">Mit uns verkaufen Sie Ihre Immobilie schnell und zu marktgerechten Preisen.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="row justify-content-md-center pt-md-4 mb-1">
                            <div className="col-sm-6 mb-3 mb-sm-0">
                                <Link to="/immobilienbewertung" className={`card card-transition bg-soft-primary shadow-none h-100`} onClick={(e) => history.push('/immobilienbewertung')}>
                                    <div className="card-body">
                                        <div className="mb-5">
                                            <img className="img-fluid rounded-2" src="../img/template-pictures/Card_5.jpeg" alt="Image Description" />
                                        </div>

                                        <h3 className="card-title text-inherit mb-1">Immobilienbewertung</h3>
                                        <span className="d-block text-body mb-3">Für Verkäufer</span>
                                        <p className="card-text text-dark">
                                            Wir beraten Sie fachgerecht und objektiv über realistische Verkaufschancen Ihrer Immobilie. Jedes angebotene Objekt wird im Vorfeld besichtigt. Hierbei
                                            begutachten wir die Immobilie und ermitteln einen marktgerechten Angebotspreis!
                                        </p>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-sm-6">
                                <Link to="/verwaltung" className={`card card-transition bg-soft-primary shadow-none h-100`} onClick={(e) => history.push('/verwaltung')}>
                                    <div className="card-body">
                                        <div className="mb-5">
                                            <img className="img-fluid rounded-2" src="../img/template-pictures/Card_3.jpeg" alt="Image Description" />
                                        </div>

                                        <h3 className="card-title text-inherit mb-0">Verwaltung</h3>
                                        <span className="d-block text-body mb-3">Für Immobilieneigentümer</span>
                                        <p className="card-text text-dark">Sie haben eine Immobilie - Wir kümmern uns um die Verwaltung für Sie.</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-4">
                        <h3>Kundenfeedback</h3>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-12 col-lg-12 mb-4">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">
                                            Herr Dipl.-Ing. Rauprecht hat über viele Jahre unser Haus, das wir nun mithilfe von Frau Rauprecht verkauft haben, mit umfassender Sachkenntnis und hoher
                                            Zuverlässigkeit verwaltet. Deshalb gab es für uns keinerlei Zweifel, Frau Rauprecht zu bitten, den Verkauf des Hauses vorzubereiten. <br />
                                            Die Familie wird doch für Qualität bürgen, war unsere Überzeugung! Sollte ein Verkäufer mit dem Gedanken spielen, die beim erfolgreichen Verkauf anfallenden
                                            Kosten einsparen zu wollen, so verzichtet er auf die so wertvollen Details, die, wenn sie nicht beachtet werden, ein Desaster auslösen können.
                                            <br /> Der Verkäufer kann sich dagegen ruhig und gelassen zurücklehnen und Frau Rauprecht voll und ganz vertrauen.
                                            <br /> Sie denkt mit ihrem umfangreichen Erfahrungsschatz einfach an alles und ist fortwährend bemüht, den Verkäufer vor Fehlern zu bewahren und dies in
                                            einer gewinnenden Liebenswürdigkeit. Hervorzuheben ist auch Ihr umgehendes Handeln bei jedem Schritt, der beim Hausverkauf zu berücksichtigen ist.
                                            <br /> Wenn ein potenzieller Verkäufer eine Maklerin/Makler auswählen will, so können wir Rauprecht-Immobilien mit absolut gutem Gewissen empfehlen.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img8.jpg'}`} alt="Image Description" />
                                        </div> 
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="card-title mb-0">R. Ehrnsperger</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>*/}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Brigitte und Helmut P.</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                        <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">
                                            An Frau Rauprecht hat mir auch ihre absolute Zuverlässigkeit und ihr voller Einsatz bei der Abwicklung des Geschäftes imponiert. Hätte ich noch weitere
                                            Immobiliengeschäfte zu erledigen, so würde ich ohne zu zögern auf jeden Fall Frau Rauprecht wieder zu Rate ziehen. Ich wünsche Frau Rauprecht viel Erfolg in
                                            ihrer weiteren Tätigkeit.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img8.jpg'}`} alt="Image Description" />
                                        </div> 
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="card-title mb-0">R. Ehrnsperger</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>*/}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">R. Ehrnsperger</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                        <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">
                                            Frau Rauprecht hat zu jeder Zeit eine große Einsatzbereitschaft gezeigt. Mit viel Übersicht hat sie in allen Situationen ihre Kompetenz bewiesen. Es war
                                            eine respekt- und vertrauensvolle Zusammenarbeit. Ich wünsche Frau Rauprecht noch viele zufriedene Kunden.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img9.jpg'}`} alt="Image Description" />
                                        </div> */}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">A. Rau</h5>
                                            <p className="card-text small">Wohnungsverkäuferin</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                        <div className="col-sm-6 col-lg-4">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">
                                            Nachdem wir mehrere Makler getestet haben und nicht zufrieden waren, haben wir Frau Rauprecht entdeckt. In ihr hatten wir eine sympatische, seriöse und
                                            kompetente Ansprechpartnerin, die alle Termine zuverlässig und pünktlich eingehalten hat. Sie war zu jeder Zeit telefonisch und per E-Mail erreichbar. Wir
                                            waren mit dem Verkauf unseres Hauses außerordentlich zufrieden und würden uns jederzeit wieder an sie wenden.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img3.jpg'}`} alt="Image Description" />
                                        </div> */}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Familie Jordan</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                    <div className="row">
                        <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">
                                            Ich habe mich die gesamte Zeit während meines Hausverkaufs sehr gut vertreten gefühlt. Frau Rauprecht, von der FA. Rauprecht Immobilien hat eine große
                                            fachliche Kompetenz. Sie hat den gesamten Verkauf für mich sehr einfach und unkompliziert gemacht. Ich kann sie jedem Hausverkäufer nur empfehlen.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img8.jpg'}`} alt="Image Description" />
                                        </div> 
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="card-title mb-0">R. Ehrnsperger</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>*/}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">N. Schulz</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                        <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">Die Betreuung und Unterstützung übertraf eindeutig unsere Erwartungen. Wir danken von Herzen und empfehlen Firma Rauprecht absolut.</p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img9.jpg'}`} alt="Image Description" />
                                        </div> */}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Rita D.</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                        <div className="col-sm-6 col-lg-4">
                            {/* Card */}
                            <div className="card h-100">
                                <div className="card-body">
                                    {/* Rating */}
                                    <div className="d-flex gap-1 mb-2">
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                        <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                    </div>
                                    {/* End Rating */}
                                    <div className="mb-auto">
                                        <p className="card-text">
                                            Frau Rauprecht ist eine sehr freundliche und engagierte Maklerin. Vom ersten Kontakt bis zum Hausverkauf wurden wir sehr kompetent und professionell von ihr
                                            beraten und betreut. Sie ist immer erreichbar und wir haben in allen Belangen eine verbindliche und für uns verständliche Antwort erhalten.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer pt-0">
                                    {/* Media */}
                                    <div className="d-flex align-items-center">
                                        {/* <div className="flex-shrink-0">
                                            <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/assets/img/160x160/img3.jpg'}`} alt="Image Description" />
                                        </div> */}
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Christiane K.</h5>
                                            <p className="card-text small">Immobilienverkäufer</p>
                                        </div>
                                    </div>
                                    {/* End Media */}
                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </div>
                {/* End Testimonials */}

                <div className="container content-space-1 content-space-lg-1">
                    <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-4">
                        <h3>Neuigkeiten</h3>
                    </div>
                    <div className="card card-stretched-vertical  mb-4">
                        <div className="row gx-0">
                            <div className="col-lg-8">
                                <div className="shape-container overflow-hidden">
                                    <img className="card-img" src="../img/IMG-20230208-WA0002.JPEG" alt="Image Description" />
                                    {/* <div className="shape shape-end d-none d-lg-block zi-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100.1 1920" height="101%">
                                            <path fill="#fff" d="M0,1920c0,0,93.4-934.4,0-1920h100.1v1920H0z" />
                                        </svg>
                                    </div>
                                    <div className="shape shape-bottom d-lg-none zi-1" style={{ marginBottom: '-.25rem' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                                        </svg>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-body">
                                    <h3 className="card-title">Rauprecht Immobilien Cup 2023</h3>
                                    <p className="card-text">
                                        <br />
                                        Am 11.02.2023 fand im brandenburgischen Schönow bei Berlin ein spannendes Fußballturnier statt. Der Rauprecht Immobilien Cup richtete sich an Mannschaften der
                                        Altersklasse D und wurde von insgesamt 8 Teams bestritten.
                                        <br />
                                        <br />
                                        <img className="img-fluid" src={`../img/IMG-5208.JPEG`} alt="Image Description" style={{ borderRadius: '5%' }} />
                                        <br />
                                        <br />
                                        Das Turnier fand in der neuen Sporthalle der Grundschule Schönow statt.
                                        <br />
                                        <br />
                                        <img className="img-fluid" src={`../img/IMG-5242.JPEG`} alt="Image Description" style={{ borderRadius: '5%' }} />
                                        <br />
                                        <br />
                                        Es war ein spannendes und unterhaltsames Event. Die Mannschaften Platz 1 bis 3 erhielten einen Pokal, alle Teilnehmer des Turniers eine Medaille und Urkunde.
                                    </p>
                                    <div className="card-footer">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 avatar-group avatar-group-xs">
                                                <span className="avatar avatar-xs avatar-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Katrin Rauprecht">
                                                    <img className="avatar-img" src="../img/katrinrauprecht.png" alt="Image Description" />
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex justify-content-end">
                                                    <p className="card-text">11.02.2023</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-stretched-vertical  mb-4">
                        <div className="card-header">
                            <h3 className="card-title text-center">Sonderveröffentlichung "Erfolgreiche Unternehmerinnen" im Märkischen Sonntag</h3>
                        </div>
                        <div className="row mt-3 gx-0">
                            <div className="col-lg-12">
                                <a href="../img/moz_20220306.pdf" target="_blank">
                                    <div className="shape-container overflow-hidden">
                                        <img className="card-img" src="../img/moz_20220306.png" alt="Image Description" />
                                        {/* <div className="shape shape-end d-none d-lg-block zi-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100.1 1920" height="101%">
                                                <path fill="#fff" d="M0,1920c0,0,93.4-934.4,0-1920h100.1v1920H0z" />
                                            </svg>
                                        </div>
                                        <div className="shape shape-bottom d-lg-none zi-1" style={{ marginBottom: '-.25rem' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                                            </svg>
                                        </div> */}
                                    </div>
                                </a>
                            </div>

                            <div className="card-footer">
                                <a type="button" className="btn btn-white btn-block w-100 mb-3" href="../img/moz_20220306.pdf" target="_blank">
                                    zum Originalartikel
                                </a>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 avatar-group avatar-group-xs">
                                        <span className="avatar avatar-xs avatar-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Katrin Rauprecht">
                                            <img className="avatar-img" src="../img/katrinrauprecht.png" alt="Katrin Rauprecht" />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="d-flex justify-content-end">
                                            <p className="card-text">06.03.2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card card-stretched-vertical  mb-4">
                        <div className="card-header">
                            <h3 className="card-title text-center">Sonderveröffentlichung "Ein Tag in Bernau und Umgebung" im Märkischen Sonntag</h3>
                        </div>
                        <div className="row mt-3 gx-0">
                            <div className="col-lg-12">
                                <a href="../img/maerkischer_sonntag_artikel.pdf" target="_blank">
                                    <div className="shape-container overflow-hidden">
                                        <img className="card-img" src="../img/pressemitteilung_moz.png" alt="Image Description" />
                                        {/* <div className="shape shape-end d-none d-lg-block zi-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100.1 1920" height="101%">
                                                <path fill="#fff" d="M0,1920c0,0,93.4-934.4,0-1920h100.1v1920H0z" />
                                            </svg>
                                        </div>
                                        <div className="shape shape-bottom d-lg-none zi-1" style={{ marginBottom: '-.25rem' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                                <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                                            </svg>
                                        </div> */}
                                    </div>
                                </a>
                            </div>

                            <div className="card-footer">
                                <a type="button" className="btn btn-white btn-block w-100 mb-3" href="../img/maerkischer_sonntag_artikel.pdf" target="_blank">
                                    zum Originalartikel
                                </a>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 avatar-group avatar-group-xs">
                                        <span className="avatar avatar-xs avatar-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Katrin Rauprecht">
                                            <img className="avatar-img" src="../img/katrinrauprecht.png" alt="Katrin Rauprecht" />
                                        </span>
                                        <span className="avatar avatar-xs avatar-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Uwe Rauprecht">
                                            <img className="avatar-img" src="../img/uwe_rauprecht.png" alt="Uwe Rauprecht" />
                                        </span>
                                    </div>
                                    <div className="flex-grow-1">
                                        <div className="d-flex justify-content-end">
                                            <p className="card-text">06.08.2019</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-stretched-vertical">
                        <div className="row gx-0">
                            <div className="col-lg-8">
                                <div className="shape-container overflow-hidden">
                                    <img className="card-img" src="../img/template-pictures/foto_ivd.jpeg" alt="Image Description" />
                                    <div className="shape shape-end d-none d-lg-block zi-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100.1 1920" height="101%">
                                            <path fill="#fff" d="M0,1920c0,0,93.4-934.4,0-1920h100.1v1920H0z" />
                                        </svg>
                                    </div>
                                    <div className="shape shape-bottom d-lg-none zi-1" style={{ marginBottom: '-.25rem' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                                            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="card-body">
                                    <h3 className="card-title">Seit April 2015 ist Katrin Rauprecht DEKRA Sachverständige für Immobilienbewertung D1Plus</h3>
                                    <p className="card-text">
                                        <br />
                                        Fachgebiet: Marktwertgutachten für unbebaute Grundstücke und bebaute Wohngrundstücke einschließlich Rechte, Belastungen und grundstücksgleiche Rechte.
                                    </p>
                                    <div className="card-footer">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 avatar-group avatar-group-xs">
                                                <span className="avatar avatar-xs avatar-circle" data-bs-toggle="tooltip" data-bs-placement="top" title="Katrin Rauprecht">
                                                    <img className="avatar-img" src="../img/katrinrauprecht.png" alt="Image Description" />
                                                </span>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex justify-content-end">
                                                    <p className="card-text">11.04.2015</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandingPage;

import { Route, withRouter, useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import AboutUsPage from '../../../pages/components/AboutUsPage';
import AGBPage from '../../../pages/components/AGBpage';
import DataSecurityPage from '../../../pages/components/DataSecurityPage';
import DetailPage from '../../../pages/components/DetailPage';
import LandingPage from '../../../pages/components/LandingPage';
import OfferPage from '../../../pages/components/OfferPage';
import PropertyManagementPage from '../../../pages/components/PropertyManagementPage';
import SellPage from '../../../pages/components/SellPage';
import SitemapPage from '../../../pages/components/SitemapPage';
import ValuationPage from '../../../pages/components/ValuationPage';
import Footer from './MainWindow/Footer/Footer';
import Navbar from './MainWindow/Navbar/Navbar';
import AppPage from '../../../pages/components/AppPage';

const MainWrapper = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Navbar />
            <main id="content" className="content-space-3" role="main">
                <Route path="/" exact component={LandingPage} />
                <Route path="/angebote" exact component={OfferPage} />
                <Route exact path="/angebote/:id" render={({ match }) => <DetailPage itemId={String(match.params.id)} />} />
                <Route exact path="/angebote-app/:id/:passcode" render={({ match }) => <AppPage itemId={String(match.params.id)} passcode={String(match.params.passcode)} />} />
                <Route path="/verkauf" exact component={SellPage} />
                <Route path="/immobilienbewertung" exact component={ValuationPage} />
                <Route path="/verwaltung" exact component={PropertyManagementPage} />
                <Route path="/wir" exact component={AboutUsPage} />
                <Route path="/agb" exact component={AGBPage} />
                <Route path="/impressum" exact component={SitemapPage} />
                <Route path="/datenschutz" exact component={DataSecurityPage} />
            </main>
            <Footer />
        </>
    );
};

export default MainWrapper;

import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation(['benit-homepage', 'general']);

    const collapseNavBarAndNavigateTo = (navigationTarget: string) => {
        document.getElementById('navbarNavDropdown')!.classList.remove('show');
        document.getElementById('navbarButtonToggler')!.classList.add('collapsed');
        document.getElementById('navbarButtonToggler')!.setAttribute('aria-expanded', 'false');
        history.push(`/${navigationTarget}`);
    };

    return (
        <header id="header" className="navbar navbar-expand-xl navbar-end navbar-light bg-white navbar-sticky-top">
            <div className="container">
                <nav className="js-mega-menu navbar-nav-wrap">
                    <Link to="/" className={`navbar-brand`} onClick={(e) => history.push('/')}>
                        <img className="navbar-brand-logo" style={{ height: 'auto', width: 'auto' }} src={`${process.env.PUBLIC_URL + '/img/logo_rp.png'}`} alt="Logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        id="navbarButtonToggler"
                    >
                        <span className="navbar-toggler-default">
                            <i className="bi-list"></i>
                        </span>
                        <span className="navbar-toggler-toggled">
                            <i className="bi-x"></i>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className={`nav-link ${location.pathname === '/' ? 'active text-blue' : null}`}
                                    onClick={(e) => {
                                        collapseNavBarAndNavigateTo('');
                                    }}
                                >
                                    Start
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/verkauf"
                                    className={` nav-link ${location.pathname.startsWith('/verkauf') ? 'active text-blue' : null}`}
                                    onClick={(e) => collapseNavBarAndNavigateTo('verkauf')}
                                >
                                    Immobilie verkaufen
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/immobilienbewertung"
                                    className={` nav-link ${location.pathname.startsWith('/immobilienbewertung') ? 'active text-blue' : null}`}
                                    onClick={(e) => collapseNavBarAndNavigateTo('immobilienbewertung')}
                                >
                                    Immobilie bewerten
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/angebote"
                                    className={` nav-link ${location.pathname.startsWith('/angebote') ? 'active text-blue' : null}`}
                                    onClick={(e) => collapseNavBarAndNavigateTo('angebote')}
                                >
                                    Immobilie kaufen
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link
                                    to="/verwaltung"
                                    className={` nav-link ${location.pathname.startsWith('/verwaltung') ? 'active text-blue' : null}`}
                                    onClick={(e) => collapseNavBarAndNavigateTo('verwaltung')}
                                >
                                    Immobilienverwaltung
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                                <Link to="/wir" className={` nav-link ${location.pathname.startsWith('/wir') ? 'active text-blue' : null}`} onClick={(e) => history.push('/wir')}>
                                    Über Uns
                                </Link>
                            </li> */}
                            <li className="nav-item">
                                <a className="btn btn-primary btn-transition" href="https://portal.rauprecht-immobilien.de/" target="_blank">
                                    Login
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Navbar;

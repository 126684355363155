import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

const DataSecurityPage = () => {
    return (
        <>
            {/* Team */}
            <div className="container">
                {/* Heading */}
                <div className="w-md-80 w-lg-50 text-center mx-md-auto">
                    <h2>Datenschutzerklärung</h2>
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item mb-5">
                                Wir, die Firma Rauprecht Immobilien GmbH, nehmen den Schutz Ihrer persönlichen Daten sehr ernst, behandeln Ihre Daten vertraulich und halten uns strikt an die Regeln
                                der Datenschutzvorschriften und dieser Datenschutzerklärung.
                            </li>
                            <li className="list-item mb-5">
                                Wir freuen uns, dass Sie mit uns Kontakt aufgenommen haben. Sie interessieren sich für unsere Leistungen als Immobilienmakler. Mit dieser Anforderung werden bei uns
                                personenbezogene Daten erhoben. Mit folgenden Informationen möchten wir Ihnen einen Überblick über die Verarbeitung Ihrer personenbezogenen Daten durch uns gemäß der
                                Datenschutz-Grundverordnung (DS-GVO) in Verbindung mit dem Berliner Datenschutzgesetz und Ihre diesbezüglichen Rechte geben.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">1. Wer ist für die Erhebung Ihrer Daten verantwortlich und an wen können Sie sich wenden?</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">Rauprecht Immobilien GmbH</li>
                            <li className="list-item">Geschäftsführer: Katrin Rauprecht & Uwe Rauprecht</li>
                            <li className="list-item">Dorfstraße 42 - Am Kreisverkehr -</li>
                            <li className="list-item">D - 16321 Bernau bei Berlin</li>
                            <li className="list-item">E-Mail: info@rauprecht-immobilien.de</li>
                            <li className="list-item">Web: www.rauprecht-immobilien.de</li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">2. Zu welchem Zweck verarbeiten wir Ihre Daten und auf welcher Rechtsgrundlage?</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">a) Umfang und Zweck der Datenverarbeitung (Artikel 13 I c DS-GVO)</li>
                            <li className="list-item mb-5">
                                Zur Bearbeitung Ihres Auftrags, erheben wir folgende Informationen: Anrede, Vorname, Nachname, Anschrift, E-Mail-Adresse, Telefonnummer (Festnetz und/oder Mobilfunk),
                                Angaben zu vereinbarten Entgelten, Informationen über gesuchte bzw. angebotene Immobilien und Angaben, die nach Ihrem Auftrag für den An- bzw. Verkauf oder die An- bzw.
                                Vermietung der Immobilie notwendig sind; bei ernsthaftem Interesse: Geburtsdatum und -Ort sowie die Staatsangehörigkeit. Bei Eintritt in abschließende Verhandlungen
                                sind wir gesetzlich verpflichtet, eine Kopie Ihres Identifikationsnachweises zu erstellen. Diese Daten erheben wir, um Sie als Partner des erteilten Auftrags zu
                                identifizieren und den Auftrag ordnungsgemäß bearbeiten zu können, Korrespondenz mit Ihnen zu führen, zur Rechnungsstellung, zur Abwicklung von Ansprüchen aus dem
                                Auftragsverhältnis sowie zur Erfüllung unserer Dokumentations- und Aufbewahrungspflichten nach MaBV (§§ 10, 11 MaBV) und dem Gesetz zur Bekämpfung der Geldwäsche (§§ 8
                                II, 11, 12 GwG).
                            </li>
                            <li className="list-item">b) Rechtsgrundlage für die Verarbeitung Ihrer Daten (Artikel 13 I c, d DS-GVO)</li>
                            <li className="list-item mb-5">
                                Das Erheben und die Verarbeitung Ihrer Daten erfolgt auf Ihre Anfrage hin und ist nach Art. 6 I b DS-GVO für die Bearbeitung und Erfüllung des erteilten Auftrags, zur
                                Wahrung unserer berechtigten Interessen nach Art 6 I f DSGVO (Anspruchsprüfung) und nach Art 6 I c DS-GVO für die Erfüllung unserer gesetzlichen Pflichten nach MaBV und
                                GwG (s.o.) zulässig.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">3. Wer bekommt Ihre Daten?</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item mb-3">
                                Ihre Daten werden ausschließlich im Inland gespeichert. Um vertragsgemäß Hauptvertragsverhandlungen (Kauf/Miete etc.) anzubahnen, erfolgt die Weitergabe an den
                                Hauptvertragspartner, an die zur Vertragserstellung beauftragten Person (Notar, Rechtsanwalt oder Hausverwaltung) sowie an einen anderen Makler im Rahmen eines
                                Gemeinschaftsgeschäfts zur Bearbeitung des Auftrags oder soweit die Daten zur Immobilie im Wege der üblichen Inserate wiedergegeben werden, im veröffentlichten Umfang
                                an die Adressaten des Inserats. Soweit eine gesetzliche Pflicht besteht, erfolgt auf Anforderung die Offenlegung gegenüber dieser Stelle bzw. Behörde. Darüber hinaus
                                geben wir die erhobenen Daten geben nur mit Ihrer Zustimmung weiter (Artikel 13 I lit. e und f DS-GVO). Die Fa. Rauprecht Immobilien GmbH erhebt und speichert
                                automatisch in ihren Server Log Files Informationen, die Ihr Browser an uns übermittelt. Dies sind:
                            </li>
                            <li className="list-pointer-item">Browsertyp/ -version</li>
                            <li className="list-pointer-item">verwendetes Betriebssystem</li>
                            <li className="list-pointer-item">Referrer URL (die zuvor besuchte Seite)</li>
                            <li className="list-pointer-item">Hostname des zugreifenden Rechners (IP Adresse)</li>
                            <li className="list-pointer-item mb-5">Uhrzeit der Serveranfrage.</li>
                            <li className="list-item">
                                Diese Daten sind für uns nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen, die Daten werden zudem
                                nach einer statistischen Auswertung gelöscht. Die Internetseiten verwenden ggf. an mehreren Stellen so genannte Cookies. Sie dienen dazu, unser Angebot
                                nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten
                                der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere dienen dazu Sie beim nächsten Besuch
                                wiederzuerkennen. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
                                Cookies informiert werden und/oder diese nur nach Ihrer individuellen Zustimmung gesetzt werden. Auch können Sie Ihren Browser so einstellen, dass er gänzlich das
                                setzen von Cookies verhindert. Dies kann jedoch zu Beeinträchtigung der Funktionalität dieser und anderer Webseiten führen. Unsere Website nutzt einen Service von
                                Immobilienscout24, welches verlinkt oder als iframe eingebunden wird und unsere bei Immobilienscout24 eingestellten Objekte auf unserer Website darstellt. Wenn Sie die
                                Objekte auf unserer Website öffnen, werden die von Ihnen besuchten Webseiten und Objekte Dritten bekanntgegeben. Anbieter ist Immobilien Scout GmbH, Andreasstr. 10,
                                10243 Berlin. Details zum Umgang mit Ihren Daten durch Immobilienscout24 entnehmen Sie bitte den Datenschutzhinweisen von Immobilienscout24:
                                https://www.immobilienscout24.de/agb/datenschutz.html Ihre persönlichen Daten im Service von Immobilienscout24 werden per verschlüsselter SSL-Verbindung übertragen.
                                Rechtsgrundlage für diese Nutzung ist Art. 6 Abs. 1 lit. f DSGVO dar, wobei unser Interesse in einer ansprechenden Darstellung unserer Online-Angebote liegt. Wenn Sie
                                uns über eines unserer Kontaktformulare kontaktieren und damit Ihre Daten zukommen lassen, werden diese von Ihnen übermittelten Daten zum Zwecke der Bearbeitung Ihrer
                                Anfrage und für den Fall von Anschlussfragen bei uns gespiechert. Auch diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">4. Wie lange werden wir Ihre Daten speichern?</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Wir speichern Ihre für die Durchführung des Auftrags erhobenen personen- oder immobilienbezogenen Daten bis zum Ablauf der gesetzlichen Aufbewahrungspflicht gem. MaBV
                                und GwG (5 Jahre nach Ablauf des Kalenderjahres, in dem der Auftrag endet). Danach löschen wir Ihre Daten, es sei denn, dass wir nach Artikel 6 I c DS-GVO aufgrund von
                                steuer- und handelsrechtlichen Aufbewahrungs- und Dokumentationspflichten (Abgabenordnung, Handelsgesetzbuch) zu einer längeren Speicherung verpflichtet sind
                                (Buchungsbelege 10 Jahre, Handelsbriefe/-mails, 6 Jahre), Sie in eine darüber hinausgehende Speicherung nach Art. 6 I a DS-GVO eingewilligt haben oder, soweit es für
                                die Dauer offener Ansprüche aus dem Auftragsverhältnis zu deren Prüfung und Nachweis erforderlich ist, bis zum Ablauf der Verjährungsdauer.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">5. Welche Datenschutzrechte haben Sie?</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Sie haben das Recht auf Auskunft (Art. 15 DS-GVO), auf Berichtigung (Art. 16 DS-GVO), auf Löschung (Art. 17 DS-GVO), auf Einschränkung der Verarbeitung (Art. 18
                                DS-GVO), das Recht des Widerspruchs aus Art. 21 DS-GVO sowie das Recht auf Datenübertragbarkeit (Art. 20 DS-GVO). Beim Auskunftsrecht und Löschungsrecht gelten
                                Einschränkungen nach §§ 34,35 BDSG. Darüber hinaus besteht das Beschwerderecht bei einer Datenschutzaufsichtsbehörde, Art. 77 DS-GVO, § 19 BDSG. Ihre Einwilligung in
                                die Verarbeitung personenbezogener Daten können Sie jederzeit uns gegenüber widerrufen. Dies gilt auch für den Widerruf von Einwilligungserklärungen, die vor der
                                Geltung der DS-GVO uns gegenüber erteilt worden sind. Bitte beachten Sie, dass der Widerruf erst für die Zukunft wirkt.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">6. Ihr Widerspruchsrecht nach Art. 21 DS-GVO</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die
                                aufgrund von Art. 6 I e DS-GVO (Datenverarbeitung im öffentlichen Interesse) und Art. 6 I f DS-GVO (Datenverarbeitung aufgrund Grundlage einer Interessenabwägung)
                                erfolgt, Widerspruch einzulegen. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, es bestehen zwingende
                                schutzwürdige Gründe für die Verarbeitung, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen oder wenn die Verarbeitung der Geltendmachung, Ausübung
                                oder Verteidigung von Rechtsansprüchen dient. Ein Widerspruch kann formfrei erfolgen. Es genügt eine E-Mail an folgende Adresse: info@rauprecht-immobilien.de.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">7. SSL-Verschlüsselte Übertragung</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Zum Schutz Ihrer persönlichen Daten werden sämtliche Daten, die Sie an uns über unsere Website oder an unsere Mailadressen senden, mittels SSL/TLS Zertifikat
                                verschlüsselt übertragen. Dadurch können die Daten die Sie uns übermitteln nicht von Dritten mitgelesen werden. Zur Ihrer Sicherheit können eine verschlüsselte
                                Verbindung überprüfen indem in der Adresszeile Ihres Browsers ein Schloss zu sehen ist und die Adresszeile von http:// auf https:// wechselt.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">8. Rechtliche Grundlagen</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Artikel 6 DSGVO. Sollten Sie Fragen haben, können Sie sich gern an uns wenden.</li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">9. Weitere Informationen</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit Rede und Antwort bezüglich der Verarbeitung Ihrer personenbezogenen Daten stehen. Wenn Sie Fragen
                                haben, die Ihnen diese Datenschutzerklärung nicht beantworten konnte oder wenn Sie zu einem Punkt vertiefte Informationen wünschen, wenden Sie sich bitte jederzeit an
                                uns: info@rauprecht-immobilien.de.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
            </div>
            {/* End Team */}
        </>
    );
};

export default DataSecurityPage;

// @ts-nocheck
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

const ValuationPage = () => {
    const history = useHistory();
    const location = useLocation();
    const [request, setRequest] = useState<{ [x: string]: any }>({
        propertyType: 'Einfamilienhaus',
        livingSpace: '80',
        plotArea: '120',
        numberOfRooms: '4',
        constructionYear: '1990',
        condition: 'Normal',
        address: '',
        priceFrom: '200000',
        priceTo: '300000',
    });
    const [inputUpdate, setInputUpdate] = useState<{ [x: string]: any }>();

    const { ri_homepage_send_valuation_request } = useActions();

    const { valuationRequestSuccessful } = useTypedSelector((state) => state.riReducer);

    useEffect(() => {
        // INITIALIZATION OF STICKY BLOCKS
        // =======================================================

        new HSStickyBlock('.js-sticky-block', {
            targetSelector: '#header',
            //targetSelector: document.getElementById('header') ? (document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null) : null,
        });

        // INITIALIZATION OF STEP FORM
        // =======================================================

        new HSStepForm('.js-step-form', {
            finish: () => {
                document.getElementById('uploadResumeStepFormProgress').style.display = 'none';

                document.getElementById('uploadResumeStepFormContent').style.display = 'none';

                document.getElementById('successMessageContent').style.display = 'block';
                scrollToTop('#header_section');
                const formContainerEg1 = document.getElementById('formContainer');

                formContainerEg1.classList.remove('col-lg-8');

                formContainerEg1.classList.add('col-lg-12');
            },
            onNextStep: function () {
                scrollToTop();
            },
            onPrevStep: function () {
                scrollToTop();
            },
        });

        function scrollToTop(el = '.js-step-form') {
            el = document.querySelector(el);
            window.scrollTo({
                top: el.getBoundingClientRect().top + window.scrollY - 90,
                left: 0,
                behavior: 'smooth',
            });
        }

        // INITIALIZATION OF ADD FIELD
        // =======================================================

        new HSAddField('.js-add-field', {
            addedField: (field) => {
                HSCore.components.HSQuill.init(field.querySelector('.js-quill-dynamic'));
            },
        });

        // INITIALIZATION OF QUILLJS EDITOR
        // =======================================================

        HSCore.components.HSQuill.init('.js-quill');

        // INITIALIZATION OF INPUT MASK
        // =======================================================

        HSCore.components.HSMask.init('.js-input-mask');

        //HSCore.components.HSNoUISlider.init('.js-nouislider');

        var livingSpaceSlider = document.getElementById('livingSpaceSlider');

        noUiSlider.create(livingSpaceSlider, {
            range: {
                min: 0,
                max: 800,
            },
            step: 1,
            showTooltips: false,
            connect: [true, false],
            start: 80,
        });

        livingSpaceSlider.noUiSlider.on('update', function (values, handle) {
            setInputUpdate({ type: 'livingSpace', value: Math.floor(values[0]) });
        });

        var plotAreaSlider = document.getElementById('plotAreaSlider');

        noUiSlider.create(plotAreaSlider, {
            range: {
                min: 0,
                max: 1000,
            },
            step: 1,
            showTooltips: false,
            connect: [true, false],
            start: 120,
        });

        plotAreaSlider.noUiSlider.on('update', function (values, handle) {
            setInputUpdate({ type: 'plotArea', value: Math.floor(values[0]) });
        });

        var numberOfRoomsSlider = document.getElementById('numberOfRoomsSlider');

        noUiSlider.create(numberOfRoomsSlider, {
            range: {
                min: 0,
                max: 12,
            },
            step: 1,
            showTooltips: false,
            connect: [true, false],
            start: 4,
        });

        numberOfRoomsSlider.noUiSlider.on('update', function (values, handle) {
            setInputUpdate({ type: 'numberOfRooms', value: Math.floor(values[0]) });
        });

        var priceSlider = document.getElementById('priceSlider');

        noUiSlider.create(priceSlider, {
            range: {
                min: 50000,
                max: 800000,
            },
            step: 1000,
            showTooltips: false,
            connect: [false, true, false],
            start: [200000, 300000],
        });

        priceSlider.noUiSlider.on('update', function (values, handle) {
            setInputUpdate({ type: 'priceFrom', value: Math.floor(values[0]) });
            setInputUpdate({ type: 'priceTo', value: Math.floor(values[1]) });
        });
    }, []);

    useEffect(() => {
        if (inputUpdate && inputUpdate.type) {
            setRequest({ ...request, [inputUpdate.type]: inputUpdate.value });
        }
    }, [inputUpdate]);

    const resetSliders = () => {
        document.getElementById('livingSpaceSlider').noUiSlider.set(80);
        document.getElementById('plotAreaSlider').noUiSlider.set(120);
        document.getElementById('numberOfRoomsSlider').noUiSlider.set(4);
    };

    return (
        <>
            <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9" id="header_section">
                <span className="text-cap">Immobilienbewertung</span>
                <h2>ein kostenloser Service für Sie </h2>
            </div>
            <div className="container">
                {/* Step Form */}
                <form
                    className="js-step-form"
                    data-hs-step-form-options='{
    "progressSelector": "#uploadResumeStepFormProgress",
    "stepsSelector": "#uploadResumeStepFormContent",
    "endSelector": "#uploadResumeFinishBtn",
    "isValidate": false
  }'
                >
                    <div className="row">
                        <div className="col-lg-4 d-none d-lg-block">
                            {/* Sticky Block */}
                            <div id="stickyBlockStartPoint">
                                <div
                                    className="js-sticky-block"
                                    data-hs-sticky-block-options='{
           "parentSelector": "#stickyBlockStartPoint",
           "breakpoint": "lg",
           "startPoint": "#stickyBlockStartPoint",
           "endPoint": "#stickyBlockEndPoint",
           "stickyOffsetTop": 20,
           "stickyOffsetBottom": 0
         }'
                                >
                                    {/* Step */}
                                    <ul id="uploadResumeStepFormProgress" className="js-step-progress step step-icon-xs step-border-last-0 mt-5 ">
                                        <li className="step-item">
                                            <a
                                                className="step-content-wrapper"
                                                href="javascript:;"
                                                data-hs-step-form-next-options='{
              "targetSelector": "#uploadResumeStepBasics"
            }'
                                            >
                                                <span className="step-icon step-icon-soft-dark">1</span>
                                                <div className="step-content">
                                                    <span className="step-title">Basis Informationen</span>
                                                    <span className="step-title-description step-text">Generelle Informationen über Ihre Immobilie</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="step-item">
                                            <a
                                                className="step-content-wrapper"
                                                href="javascript:;"
                                                data-hs-step-form-next-options='{
              "targetSelector": "#uploadResumeStepEducation"
            }'
                                            >
                                                <span className="step-icon step-icon-soft-dark">2</span>
                                                <div className="step-content">
                                                    <span className="step-title">Detaillierte Informationen</span>
                                                    <span className="step-title-description step-text">Relevante Informationen zur Bewertungs-Vorbereitung</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="step-item">
                                            <a
                                                className="step-content-wrapper"
                                                href="javascript:;"
                                                data-hs-step-form-next-options='{
              "targetSelector": "#uploadResumeStepWork"
            }'
                                            >
                                                <span className="step-icon step-icon-soft-dark">3</span>
                                                <div className="step-content">
                                                    <span className="step-title">Persönliche Preisvorstellung</span>
                                                    <span className="step-title-description step-text">Ihre Preisvorstellungen helfen uns die Bewertung für Sie einzuordnen</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="step-item">
                                            <a
                                                className="step-content-wrapper"
                                                href="javascript:;"
                                                data-hs-step-form-next-options='{
              "targetSelector": "#uploadResumeStepJobSkills"
            }'
                                            >
                                                <span className="step-icon step-icon-soft-dark">4</span>
                                                <div className="step-content">
                                                    <span className="step-title">Persönliche Angaben</span>
                                                    <span className="step-title-description step-text">Daten anhand derer wir Sie kontaktieren können</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="step-item">
                                            <a
                                                className="step-content-wrapper"
                                                href="javascript:;"
                                                data-hs-step-form-next-options='{
              "targetSelector": "#uploadResumeStepTypeOfJob"
            }'
                                            >
                                                <span className="step-icon step-icon-soft-dark">5</span>
                                                <div className="step-content">
                                                    <span className="step-title">Übermittlung der Daten</span>
                                                    <span className="step-title-description step-text">Sobald die Daten an uns übermittelt worden, setzen wir uns mit Ihnen in Kontakt</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    {/* End Step */}
                                </div>
                            </div>
                            {/* End Sticky Block */}
                        </div>
                        {/* End Col */}
                        <div id="formContainer" className="col-lg-8">
                            {/* Content Step Form */}
                            <div id="uploadResumeStepFormContent">
                                {/* Card */}
                                <div id="uploadResumeStepBasics" className="card active">
                                    {/* Header */}
                                    <div className="card-header bg-img-start" style={{ backgroundImage: 'url(../assets/svg/components/card-1.svg)' }}>
                                        <div className="flex-grow-1">
                                            <span className="d-lg-none">Schritt 1 von 5</span>
                                            <h3 className="card-header-title">Basis Informationen</h3>
                                            <p className="card-text">Generelle Informationen über Ihre Immobilie</p>
                                        </div>
                                    </div>
                                    {/* End Header */}
                                    {/* Body */}
                                    <div className="card-body">
                                        <div className="row gx-3">
                                            <label className="form-label fw-bold">Um was für eine Immobilie handelt es sich?</label>
                                            <div className="col-6 col-md-3 mb-3">
                                                {/* Radio Check */}
                                                <div className="form-check form-check-card text-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="typeOfListing"
                                                        id="typeOfListing1"
                                                        defaultChecked
                                                        checked={request && (!request.propertyType || request.propertyType === 'Einfamilienhaus') ? true : false}
                                                        onChange={(e: any) => {
                                                            setRequest({
                                                                ...request,
                                                                propertyType: e.target.checked ? 'Einfamilienhaus' : request.propertyType,
                                                                livingSpace: '80',
                                                                plotArea: '120',
                                                                numberOfRooms: '4',
                                                                constructionYear: '1990',
                                                                condition: 'Normal',
                                                            });
                                                            resetSliders();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="typeOfListing1">
                                                        <img className="w-50 mb-3" src="../assets/svg/illustrations/small-house.svg" alt="SVG" />
                                                        <span className="d-block">Einfamilienhaus</span>
                                                    </label>
                                                </div>
                                                {/* End Radio Check */}
                                            </div>
                                            {/* End Col */}
                                            <div className="col-6 col-md-3 mb-3">
                                                {/* Radio Check */}
                                                <div className="form-check form-check-card text-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="typeOfListing"
                                                        id="typeOfListing3"
                                                        checked={request && request.propertyType === 'Mehrfamilienhaus' ? true : false}
                                                        onChange={(e: any) => {
                                                            setRequest({
                                                                ...request,
                                                                propertyType: e.target.checked ? 'Mehrfamilienhaus' : request.propertyType,
                                                                livingSpace: '80',
                                                                plotArea: '120',
                                                                numberOfRooms: '4',
                                                                constructionYear: '1990',
                                                                condition: 'Normal',
                                                            });
                                                            resetSliders();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="typeOfListing3">
                                                        <img className="w-50 mb-3" src="../assets/svg/illustrations/multi-family-house.svg" alt="SVG" />
                                                        <span className="d-block">Mehrfamilienhaus</span>
                                                    </label>
                                                </div>
                                                {/* End Radio Check */}
                                            </div>
                                            {/* End Col */}
                                            <div className="col-6 col-md-3 mb-3">
                                                {/* Radio Check */}
                                                <div className="form-check form-check-card text-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="typeOfListing"
                                                        id="typeOfListing2"
                                                        checked={request && request.propertyType === 'Wohnung' ? true : false}
                                                        onChange={(e: any) => {
                                                            setRequest({
                                                                ...request,
                                                                propertyType: e.target.checked ? 'Wohnung' : request.propertyType,
                                                                plotArea: null,
                                                                livingSpace: '80',
                                                                numberOfRooms: '4',
                                                                constructionYear: null,
                                                                condition: 'Normal',
                                                            });
                                                            resetSliders();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="typeOfListing2">
                                                        <img className="w-50 mb-3" src="../assets/svg/illustrations/flat-house.svg" alt="SVG" />
                                                        <span className="d-block">Wohnung</span>
                                                    </label>
                                                </div>
                                                {/* End Radio Check */}
                                            </div>
                                            {/* End Col */}
                                            <div className="col-6 col-md-3 mb-3">
                                                {/* Radio Check */}
                                                <div className="form-check form-check-card text-center">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="typeOfListing"
                                                        id="typeOfListing4"
                                                        checked={request && request.propertyType === 'Grundstück' ? true : false}
                                                        onChange={(e: any) => {
                                                            setRequest({
                                                                ...request,
                                                                propertyType: e.target.checked ? 'Grundstück' : request.propertyType,
                                                                livingSpace: null,
                                                                plotArea: '120',
                                                                numberOfRooms: null,
                                                                constructionYear: null,
                                                                condition: null,
                                                            });
                                                            resetSliders();
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="typeOfListing4">
                                                        <img className="w-50 mb-3" src="../assets/svg/illustrations/farm-land.svg" alt="SVG" />
                                                        <span className="d-block">Grundstück</span>
                                                    </label>
                                                </div>
                                                {/* End Radio Check */}
                                            </div>
                                            {/* End Col */}
                                        </div>
                                        {/* End Row */}

                                        {/* Form */}

                                        <div className={`mb-4 ${request.propertyType === 'Grundstück' ? 'd-none' : ''}`}>
                                            <label htmlFor="firstNameLabel" className="form-label fw-bold">
                                                Wohnfläche in Quadratmetern
                                            </label>
                                            <div className="range-slider mb-3">
                                                <div id="livingSpaceSlider"></div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    id="livingSpaceInput"
                                                    value={request && request.livingSpace ? request.livingSpace : ''}
                                                    onChange={(e: any) => {
                                                        setRequest({ ...request, livingSpace: e.target.value });
                                                        document.getElementById('livingSpaceSlider').noUiSlider.set(e.target.value);
                                                    }}
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    m²
                                                </span>
                                            </div>
                                        </div>
                                        {/* End Form */}
                                        {/* Form */}
                                        <div className={`mb-1 ${request.propertyType === 'Wohnung' ? 'd-none' : ''}`}>
                                            <label htmlFor="firstNameLabel" className="form-label fw-bold">
                                                Grundstücksfläche in Quadratmetern
                                            </label>
                                            <div className="range-slider mb-3">
                                                <div id="plotAreaSlider"></div>
                                            </div>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    id="plotAreaInput"
                                                    value={request && request.plotArea ? request.plotArea : ''}
                                                    onChange={(e: any) => {
                                                        setRequest({ ...request, plotArea: e.target.value });
                                                        document.getElementById('plotAreaSlider').noUiSlider.set(e.target.value);
                                                    }}
                                                />
                                                <span className="input-group-text" id="basic-addon2">
                                                    m²
                                                </span>
                                            </div>
                                        </div>
                                        {/* End Form */}
                                    </div>
                                    {/* End Body */}
                                    {/* Footer */}
                                    <div className="card-footer pt-0">
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                data-hs-step-form-next-options='{
                    "targetSelector": "#uploadResumeStepEducation"
                  }'
                                            >
                                                Weiter <i className="bi-chevron-right small ms-1" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </div>
                                {/* End Card */}
                                <div id="uploadResumeStepEducation" className="card" style={{ display: 'none' }}>
                                    {/* Header */}
                                    <div className="card-header bg-img-start" style={{ backgroundImage: 'url(../assets/svg/components/card-1.svg)' }}>
                                        <div className="flex-grow-1">
                                            <span className="d-lg-none">Schritt 2 von 5</span>
                                            <h3 className="card-header-title">Detaillierte Informationen</h3>
                                            <p className="card-text">Relevante Informationen zur Bewertungs-Vorbereitung</p>
                                        </div>
                                    </div>
                                    {/* End Header */}
                                    {/* Body */}
                                    <div className="card-body">
                                        <div
                                            className="js-add-field"
                                            data-hs-add-field-options='{
                "template": "#addEducationFieldTemplate",
                "container": "#addEducationFieldContainer",
                "defaultCreated": 0
              }'
                                        >
                                            {/* Form */}
                                            <div className={`mb-4 ${request.propertyType === 'Grundstück' ? 'd-none' : ''}`}>
                                                <label htmlFor="lastNameLabel" className="form-label fw-bold">
                                                    Wieviele Zimmer hat das Objekt (ohne Küche und Bad)
                                                </label>
                                                <div className="range-slider mb-3">
                                                    <div id="numberOfRoomsSlider"></div>
                                                </div>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control text-right"
                                                        placeholder=""
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        id="rangeSliderExample2MinResult3"
                                                        value={request && request.numberOfRooms ? request.numberOfRooms : ''}
                                                        onChange={(e: any) => {
                                                            setRequest({ ...request, numberOfRooms: e.target.value });
                                                            document.getElementById('numberOfRoomsSlider').noUiSlider.set(e.target.value);
                                                        }}
                                                    />
                                                    <span className="input-group-text" id="basic-addon2">
                                                        Zimmer
                                                    </span>
                                                </div>
                                            </div>
                                            {/* End Form */}

                                            {/* Form */}
                                            <div className={`mb-4 ${request.propertyType === 'Grundstück' || request.propertyType === 'Wohnung' ? 'd-none' : ''}`}>
                                                <label htmlFor="schoolFromMonthLabel" className="form-label fw-bold">
                                                    Baujahr
                                                </label>
                                                <div className="row gx-2">
                                                    <div className="col-sm-12">
                                                        {/* Select */}
                                                        <select
                                                            className="form-select"
                                                            name="schoolFromYearSelect"
                                                            value={request && request.constructionYear ? request.constructionYear : 1990}
                                                            onChange={(e: any) => setRequest({ ...request, constructionYear: e.target.value })}
                                                        >
                                                            <option value="2022">2022</option>
                                                            <option value="2021">2021</option>
                                                            <option value="2020">2020</option>
                                                            <option value="2019">2019</option>
                                                            <option value="2018">2018</option>
                                                            <option value="2017">2017</option>
                                                            <option value="2016">2016</option>
                                                            <option value="2015">2015</option>
                                                            <option value="2014">2014</option>
                                                            <option value="2013">2013</option>
                                                            <option value="2012">2012</option>
                                                            <option value="2011">2011</option>
                                                            <option value="2010">2010</option>
                                                            <option value="2009">2009</option>
                                                            <option value="2008">2008</option>
                                                            <option value="2007">2007</option>
                                                            <option value="2006">2006</option>
                                                            <option value="2005">2005</option>
                                                            <option value="2004">2004</option>
                                                            <option value="2003">2003</option>
                                                            <option value="2002">2002</option>
                                                            <option value="2001">2001</option>
                                                            <option value="2000">2000</option>
                                                            <option value="1999">1999</option>
                                                            <option value="1998">1998</option>
                                                            <option value="1997">1997</option>
                                                            <option value="1996">1996</option>
                                                            <option value="1995">1995</option>
                                                            <option value="1994">1994</option>
                                                            <option value="1993">1993</option>
                                                            <option value="1992">1992</option>
                                                            <option value="1991">1991</option>
                                                            <option value="1990">1990</option>
                                                            <option value="1989">1989</option>
                                                            <option value="1988">1988</option>
                                                            <option value="1987">1987</option>
                                                            <option value="1986">1986</option>
                                                            <option value="1985">1985</option>
                                                            <option value="Älter">Älter</option>
                                                        </select>
                                                        {/* End Select */}
                                                    </div>
                                                    {/* End Col */}
                                                </div>
                                                {/* End Row */}
                                            </div>
                                            {/* End Form */}
                                            <div className={`row gx-3 ${request.propertyType === 'Grundstück' ? 'd-none' : ''}`}>
                                                <label className="form-label fw-bold">Zustand der Immobilie</label>
                                                <div className="col-12 col-md-4 mb-3">
                                                    {/* Form Radio */}
                                                    <label className="form-control" htmlFor="formControlRadioEg11">
                                                        <span className="form-check">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                name="formControlRadioEg"
                                                                id="formControlRadioEg11"
                                                                checked={request && request.condition === 'Einfach' ? true : false}
                                                                onChange={(e: any) => setRequest({ ...request, condition: e.target.checked ? 'Einfach' : request.condition })}
                                                            />
                                                            <span className="form-check-label">
                                                                <div className="d-block gap-1 mb-2 text-center">
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                                </div>
                                                                <span className="d-block text-center">Einfach</span>
                                                            </span>
                                                        </span>
                                                    </label>
                                                    {/* End Form Radio */}
                                                </div>
                                                {/* End Col */}
                                                <div className="col-12 col-md-4 mb-3">
                                                    {/* Form Radio */}
                                                    <label className="form-control" htmlFor="formControlRadioEg12">
                                                        <span className="form-check">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                name="formControlRadioEg"
                                                                id="formControlRadioEg12"
                                                                defaultChecked
                                                                checked={request && (!request.condition || request.condition === 'Normal') ? true : false}
                                                                onChange={(e: any) => setRequest({ ...request, condition: e.target.checked ? 'Normal' : request.condition })}
                                                            />
                                                            <span className="form-check-label">
                                                                <div className="d-block gap-1 mb-2 text-center">
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star-muted.svg'}`} alt="Review rating" width={16} />
                                                                </div>
                                                                <span className="d-block text-center">Normal</span>
                                                            </span>
                                                        </span>
                                                    </label>
                                                    {/* End Form Radio */}
                                                </div>
                                                {/* End Col */}
                                                <div className="col-12 col-md-4 mb-3">
                                                    {/* Form Radio */}
                                                    <label className="form-control" htmlFor="formControlRadioEg13">
                                                        <span className="form-check">
                                                            <input
                                                                type="radio"
                                                                className="form-check-input"
                                                                name="formControlRadioEg"
                                                                id="formControlRadioEg13"
                                                                checked={request && request.condition === 'Gehoben' ? true : false}
                                                                onChange={(e: any) => setRequest({ ...request, condition: e.target.checked ? 'Gehoben' : request.condition })}
                                                            />
                                                            <span className="form-check-label">
                                                                <div className="d-block gap-1 mb-2 text-center">
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                    <img src={`${process.env.PUBLIC_URL + '/assets/svg/illustrations/star.svg'}`} alt="Review rating" width={16} />
                                                                </div>
                                                                <span className="d-block text-center">Gehoben</span>
                                                            </span>
                                                        </span>
                                                    </label>
                                                    {/* End Form Radio */}
                                                </div>
                                                {/* End Col */}
                                            </div>
                                            {/* End Row */}
                                            {/* Form */}
                                            <div className="mb-1">
                                                <label htmlFor="lastNameLabel" className="form-label fw-bold">
                                                    Adresse der Immobilie (Straße, Hausnummer, PLZ, Ort)
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lastName"
                                                    id="lastNameLabel"
                                                    placeholder=""
                                                    aria-label="Williams"
                                                    value={request && request.address ? request.address : ''}
                                                    onChange={(e: any) => setRequest({ ...request, address: e.target.value })}
                                                />
                                            </div>
                                            {/* End Form */}
                                        </div>
                                    </div>
                                    {/* End Body */}
                                    {/* Footer */}
                                    <div className="card-footer pt-0">
                                        <div className="d-flex align-items-center">
                                            <button
                                                type="button"
                                                className="btn btn-ghost-secondary"
                                                data-hs-step-form-prev-options='{
               "targetSelector": "#uploadResumeStepBasics"
             }'
                                            >
                                                <i className="bi-chevron-left small ms-1" /> Vorheriger Schritt
                                            </button>
                                            <div className="ms-auto">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    data-hs-step-form-next-options='{
                      "targetSelector": "#uploadResumeStepWork"
                    }'
                                                    disabled={request && request.address ? false : true}
                                                >
                                                    Weiter <i className="bi-chevron-right small ms-1" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </div>
                                <div id="uploadResumeStepWork" className="card" style={{ display: 'none' }}>
                                    {/* Header */}
                                    <div className="card-header bg-img-start" style={{ backgroundImage: 'url(../assets/svg/components/card-1.svg)' }}>
                                        <div className="flex-grow-1">
                                            <span className="d-lg-none">Schritt 3 von 5</span>
                                            <h3 className="card-header-title">Persönliche Preisvorstellung</h3>
                                            <p className="card-text">Ihre Preisvorstellungen helfen uns die Bewertung für Sie einzuordnen</p>
                                        </div>
                                    </div>
                                    {/* End Header */}
                                    {/* Body */}
                                    <div className="card-body">
                                        <div
                                            className="js-add-field"
                                            data-hs-add-field-options='{
                "template": "#addWorkFieldTemplate",
                "container": "#addWorkFieldContainer",
                "defaultCreated": 0
              }'
                                        >
                                            {/* Form */}
                                            <div className="row">
                                                <label htmlFor="jobTitleLabel" className="form-label fw-bold">
                                                    Preisvorstellung
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="range-slider">
                                                    <div id="priceSlider"></div>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-sm-6">
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <span className="input-group-text" id="basic-addon2" style={{ width: '60px' }}>
                                                                von
                                                            </span>
                                                            <input
                                                                type="numeric"
                                                                className="form-control"
                                                                placeholder=""
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                                id="rangeSliderExampleDouble4MinResult"
                                                                style={{ textAlign: 'right' }}
                                                                value={request && request.priceFrom ? request.priceFrom : ''}
                                                                onChange={(e: any) => {
                                                                    document
                                                                        .getElementById('priceSlider')
                                                                        .noUiSlider.set([e.target.value, parseInt(document.getElementById('priceSlider').noUiSlider.get()[1])]);
                                                                    //setRequest({ ...request, priceFrom: e.target.value });
                                                                }}
                                                                disabled
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                €
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* End Form */}
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="mb-1">
                                                        <div className="input-group">
                                                            <span className="input-group-text" id="basic-addon2" style={{ width: '60px' }}>
                                                                bis
                                                            </span>
                                                            <input
                                                                type="numeric"
                                                                className="form-control text-right"
                                                                placeholder=""
                                                                aria-label="Recipient's username"
                                                                aria-describedby="basic-addon2"
                                                                id="rangeSliderExampleDouble4MaxResult"
                                                                style={{ textAlign: 'right' }}
                                                                value={request && request.priceTo ? request.priceTo : ''}
                                                                onChange={(e: any) => {
                                                                    setRequest({ ...request, priceTo: e.target.value });
                                                                    document
                                                                        .getElementById('priceSlider')
                                                                        .noUiSlider.set([parseInt(document.getElementById('priceSlider').noUiSlider.get()[0]), e.target.value]);
                                                                }}
                                                                disabled
                                                            />
                                                            <span className="input-group-text" id="basic-addon2">
                                                                €
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* End Form */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Add Work Input Field */}
                                    </div>
                                    {/* End Body */}
                                    {/* Footer */}
                                    <div className="card-footer pt-0">
                                        <div className="d-flex align-items-center">
                                            <button
                                                type="button"
                                                className="btn btn-ghost-secondary"
                                                data-hs-step-form-prev-options='{
               "targetSelector": "#uploadResumeStepEducation"
             }'
                                            >
                                                <i className="bi-chevron-left small ms-1" /> Vorheriger Schritt
                                            </button>
                                            <div className="ms-auto">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    data-hs-step-form-next-options='{
                      "targetSelector": "#uploadResumeStepJobSkills"
                    }'
                                                >
                                                    Weiter <i className="bi-chevron-right small ms-1" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </div>
                                <div id="uploadResumeStepJobSkills" className="card" style={{ display: 'none' }}>
                                    {/* Header */}
                                    <div className="card-header bg-img-start" style={{ backgroundImage: 'url(../assets/svg/components/card-1.svg)' }}>
                                        <div className="flex-grow-1">
                                            <span className="d-lg-none">Schritt 4 von 5</span>
                                            <h3 className="card-header-title">Persönliche Angaben</h3>
                                            <p className="card-text">Daten anhand derer wir Sie kontaktieren können</p>
                                        </div>
                                    </div>
                                    {/* End Header */}
                                    {/* Body */}
                                    <div className="card-body">
                                        {/* Form */}
                                        <div className="row gx-2">
                                            <div className="col-sm-12 mb-2 mb-sm-0">
                                                <label className="form-label fw-bold">Anrede</label>
                                                <select
                                                    className="form-select mb-4"
                                                    value={request && request.title ? request.title : ''}
                                                    onChange={(e: any) => setRequest({ ...request, title: e.target.value })}
                                                >
                                                    <option value="FrauHerr">Bitte auswählen</option>
                                                    <option value="Frau">Frau</option>
                                                    <option value="Herr">Herr</option>
                                                </select>
                                                <label className="form-label fw-bold">Vorname</label>
                                                <div className="mb-4">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullNamePropertyOverviewContactForm"
                                                        placeholder="Vorname"
                                                        aria-label="Vorname"
                                                        value={request && request.firstName ? request.firstName : ''}
                                                        onChange={(e: any) => setRequest({ ...request, firstName: e.target.value })}
                                                    />
                                                </div>
                                                <label className="form-label fw-bold">Nachname</label>
                                                <div className="mb-4">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullNamePropertyOverviewContactForm"
                                                        placeholder="Nachname"
                                                        aria-label="Nachname"
                                                        value={request && request.lastName ? request.lastName : ''}
                                                        onChange={(e: any) => setRequest({ ...request, lastName: e.target.value })}
                                                    />
                                                </div>
                                                <label className="form-label fw-bold">E-Mail</label>
                                                <div className="mb-4">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="emailPropertyOverviewContactForm"
                                                        placeholder="E-Mail"
                                                        aria-label="E-Mail"
                                                        value={request && request.email ? request.email : ''}
                                                        onChange={(e: any) => setRequest({ ...request, email: e.target.value })}
                                                    />
                                                </div>
                                                <label className="form-label fw-bold">Telefon</label>
                                                <div className="mb-4">
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        id="phoneNumberPropertyOverviewContactForm"
                                                        placeholder="Telefon"
                                                        aria-label="Telefon"
                                                        value={request && request.telephone ? request.telephone : ''}
                                                        onChange={(e: any) => setRequest({ ...request, telephone: e.target.value })}
                                                    />
                                                </div>
                                                <label className="form-label">Zusätzliche Angaben oder Fragen</label>
                                                <div className="mb-1">
                                                    <textarea
                                                        className="form-control"
                                                        id="questionPropertyOverviewContactForm"
                                                        placeholder="Ich hätte gern mehr Information über..."
                                                        aria-label="Ich hätte gern mehr Information über..."
                                                        rows={4}
                                                        defaultValue={''}
                                                        value={request && request.additionalRequest ? request.additionalRequest : ''}
                                                        onChange={(e: any) => setRequest({ ...request, additionalRequest: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Row */}
                                    </div>
                                    {/* End Body */}
                                    {/* Footer */}
                                    <div className="card-footer pt-0">
                                        <div className="d-flex align-items-center">
                                            <button
                                                type="button"
                                                className="btn btn-ghost-secondary"
                                                data-hs-step-form-prev-options='{
               "targetSelector": "#uploadResumeStepWork"
             }'
                                            >
                                                <i className="bi-chevron-left small ms-1" /> Vorheriger Schritt
                                            </button>
                                            <div className="ms-auto">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    data-hs-step-form-next-options='{
                      "targetSelector": "#uploadResumeStepTypeOfJob"
                    }'
                                                    disabled={request && request.title && request.firstName && request.lastName && request.email && request.telephone ? false : true}
                                                >
                                                    Weiter <i className="bi-chevron-right small ms-1" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </div>
                                <div id="uploadResumeStepTypeOfJob" className="card" style={{ display: 'none' }}>
                                    {/* Header */}
                                    <div className="card-header bg-img-start" style={{ backgroundImage: 'url(../assets/svg/components/card-1.svg)' }}>
                                        <div className="flex-grow-1">
                                            <span className="d-lg-none">Schritt 5 von 5</span>
                                            <h3 className="card-header-title">Übermittlung der Daten</h3>
                                            <p className="card-text">Sobald die Daten an uns übermittelt worden, setzen wir uns mit Ihnen in Kontakt</p>
                                        </div>
                                    </div>
                                    {/* End Header */}
                                    {/* Body */}
                                    <div className="card-body">
                                        {/* Form */}

                                        {/* Form */}
                                        <div className="mb-4">
                                            <label htmlFor="CVPrivacySwitch" className="form-label fw-bold">
                                                Datenschutz
                                            </label>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="CVPrivacySwitch" className="form-label text-body">
                                                        Mit der Anforderung der Immobilienbewertung stimme ich den{' '}
                                                        <Link
                                                            to="/datenschutz"
                                                            className={` link-sm link-dark ${location.pathname.startsWith('/datenschutz') ? 'active text-blue' : null}`}
                                                            target="_blank"
                                                        >
                                                            Datenschutzbestimmungen
                                                        </Link>{' '}
                                                        von Rauprecht Immobilien GmbH zu, sowie deren Nutzung meiner Daten wie in der Einwilligung beschrieben.
                                                    </label>
                                                </div>
                                                <div className="col-auto">
                                                    {/* Form Switch */}
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="CVPrivacySwitch"
                                                            checked={request && request.termsAccepted ? true : false}
                                                            onChange={(e: any) => setRequest({ ...request, termsAccepted: e.target.checked })}
                                                        />
                                                    </div>
                                                    {/* End Form Switch */}
                                                </div>
                                            </div>
                                            {/* End Row */}
                                        </div>
                                        {/* End Form */}
                                    </div>
                                    {/* End Body */}
                                    {/* Footer */}
                                    <div className="card-footer pt-0">
                                        <div className="d-flex align-items-center">
                                            <button
                                                type="button"
                                                className="btn btn-ghost-secondary"
                                                data-hs-step-form-prev-options='{
               "targetSelector": "#uploadResumeStepJobSkills"
             }'
                                            >
                                                <i className="bi-chevron-left small ms-1" /> Vorheriger Schritt
                                            </button>
                                            <div className="ms-auto">
                                                <div className="d-flex gap-2">
                                                    {/* <button
                                                        type="button"
                                                        className="btn btn-white"
                                                        data-hs-step-form-next-options='{
                      "targetSelector": "#uploadResumeStepBasics"
                    }'
                                                        onClick={(e: any) => setRequest({})}
                                                    >
                                                        Abbrechen
                                                    </button> */}
                                                    <button
                                                        id="uploadResumeFinishBtn"
                                                        type="button"
                                                        className="btn btn-primary"
                                                        disabled={
                                                            request &&
                                                            request.termsAccepted &&
                                                            request.address &&
                                                            request.title &&
                                                            request.firstName &&
                                                            request.lastName &&
                                                            request.email &&
                                                            request.telephone
                                                                ? false
                                                                : true
                                                        }
                                                        onClick={(e) => {
                                                            ri_homepage_send_valuation_request(request);
                                                        }}
                                                    >
                                                        Daten übermitteln
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Footer */}
                                </div>
                            </div>
                            {/* Message Body */}
                            <div id="successMessageContent" style={{ display: 'none' }}>
                                <div className="text-center">
                                    <img className="img-fluid mb-3" src="../assets/svg/illustrations/medal.svg" alt="Image Description" style={{ maxWidth: '15rem' }} />
                                    <div className="mb-4">
                                        <h2>Anfrage erfolgreich übermittelt!</h2>
                                        <p>Ihre Immobilienbewertungsanfrage wurde erfolgreich übermittelt. Wir werden uns in Kürze mit Ihnen in Kontakt setzen.</p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Link to="/" className={`btn btn-primary`}>
                                            Zurück zur Startseite <i className="bi-chevron-right small ms-1" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* End Message Body */}
                            {/* Sticky Block End Point */}
                            <div id="stickyBlockEndPoint" />
                        </div>
                        {/* End Col */}
                    </div>
                    {/* End Row */}
                </form>
                {/* End Step Form */}
            </div>
        </>
    );
};

export default ValuationPage;

import Swal, { SweetAlertIcon } from 'sweetalert2';

const fireToast = (icon: SweetAlertIcon, title: string) => {
    Swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon,
        title,
        timerProgressBar: true,
    });
};

export default fireToast;

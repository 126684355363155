import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

const AGBPage = () => {
    return (
        <>
            {/* Team */}
            <div className="container">
                {/* Heading */}
                <div className="w-md-80 w-lg-50 text-center mx-md-auto">
                    <h2>Allgemeine Geschäftsbedingungen</h2>
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">1. Gegenstand des Auftrags ist der Nachweis und/oder die Vermittlung zu der jeweiligen Vertragsgelegenheit.</li>
                            <li className="list-item">
                                2. Sämtliche Objektdaten erhalten wir von unseren Auftraggebern. Eine Haftung für Richtigkeit und Vollständigkeit von Gebäudedaten übernehmen wir nicht. Insbesondere
                                die Maß- und Flächenangaben sind ca.-Werte und sind durch uns nicht überprüft worden.
                            </li>
                            <li className="list-item">
                                3. Kommt infolge unseres Nachweises oder unserer Vermittlung ein wirksamer Kaufvertrag zustande, so ist der Käufer verpflichtet die Provision, wie im entsprechenden
                                Expose ausgewiesen an uns zu zahlen. Die Provision ist mit Abschluss des notariellen, wirksamen Kaufvertrages verdient und fällig.
                            </li>
                            <li className="list-item">
                                4. Die Informationen über die Vertragsgelegenheit sind nur für Sie bestimmt. Eine Weitergabe der Informationen ist nicht gestattet. Für den Fall der Weitergabe und des
                                Vertragsschlusses durch den Dritten sind Sie ebenfalls verpflichtet, die mit uns vereinbarte Provision zu zahlen.
                            </li>
                            <li className="list-item">
                                5. Die Rauprecht Immobilien GmbH untersagt dem Interessenten die Weitergabe von Informationen, die im Rahmen ihrer Tätigkeit erstellt und zur Verfügung gestellt wurden
                                wie z.B. Foto`s, Grundrisse, Baupläne, Expose`s.
                            </li>
                            <li className="list-item">
                                6. Der Eigentümer der Immobilie hat uns mit dem Vertrieb beauftragt. Es ist uns daher erlaubt, sowohl für Sie, als auch für den Eigentümer vermittelnd
                                provisionspflichtig tätig zu werden. (Für die Vermittlung von Wohnungsmietverträgen zahlt der Mieter keine Provision, wenn nicht ausdrücklich vereinbart.)
                            </li>
                            <li className="list-item">
                                7. Ist unser Kunde Kaufmann oder unterhält er keinen Wohnsitz in Deutschland, gilt als Gerichtsstand und Erfüllungsort der Geschäftssitz des Maklers.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
            </div>
            {/* End Team */}
        </>
    );
};

export default AGBPage;

import axios, { AxiosError } from 'axios';

interface RequestInput {
    url: string;
    method: string;
    body: {};
    onSuccess(response: any): void;
    onError(error: any): void;
}

function isAxiosError(error: any): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
}

const useRequest = ({ url, method, body, onSuccess, onError }: RequestInput) => {
    let errors = null;

    const doRequest = async (props = {}) => {
        try {
            //@ts-ignore
            const response: any = await axios[method](url, {
                ...body,
                ...props,
                crossdomain: true,
            });

            if (onSuccess) {
                onSuccess(response.data);
            }

            return response.data;
        } catch (err: any) {
            console.log('err: ' + err + 'url: ' + url);
            if (err.response) {
                errors = err.response.data.errors;
                if (onError) {
                    onError(errors);
                }
            }
        }
    };

    return { doRequest, errors };
};

export default useRequest;

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import fireToast from '../../../benit/central-ui/methods/fireToast';

const SellPage = () => {
    const [request, setRequest] = useState<{ [x: string]: string }>({ anrede: 'Anrede', firstName: '', lastName: '', eMail: '', telephone: '', customText: '' });
    const [requestSuccessful, setRequestSuccessful] = useState(false);

    const { ri_homepage_send_request } = useActions();

    useEffect(() => {
        // @ts-ignore
        new HSStickyBlock('.js-sticky-block', {
            // @ts-ignore
            targetSelector: '#header',
        });
        // @ts-ignore
        new HsNavScroller('.js-nav-scroller');
    }, []);

    useEffect(() => {
        if (requestSuccessful) {
            //@ts-ignore
            // const liveToast = new bootstrap.Toast(document.querySelector('#liveToast'));
            // liveToast.show();
            fireToast('success', `Anfrage erfolgreich versendet`);
        }
    }, [requestSuccessful]);

    return (
        <>
            {/* Team */}
            <div className="container">
                {/* Heading */}
                <div className="w-md-80 w-lg-50 text-center mx-md-auto">
                    <span className="d-block small font-weight-bold text-cap mb-2">Immobilienvermittlung</span>
                    <h2>So einfach verkaufen Sie Ihre Immobilie mit Rauprecht Immobilien</h2>
                </div>
                <div className={`${'row'}`}>
                    <div className={`${requestSuccessful ? 'col-lg-12' : 'col-lg-8'}`}>
                        {/* End Heading */}
                        <div className="row align-items-md-center mt-7">
                            <div className="col-lg-6  mb-7">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_385522353.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Kostenfreie Erstberatung mit sach- und marktgerechter Immobilienbewertung</h2>
                                    <p>Verkäufer fragen sich schnell, ob Ihre anvisierte Preisvorstellung realistisch ist.</p>
                                    <p>
                                        Damit der Preis Ihrer Immobilie nicht unter Ihren und über den Vorstellungen des Käufers liegen ist eine fachkundige und marktgerechte Immobilienbewertung
                                        erforderlich. Profitieren Sie hierbei von unserer jahrelangen Erfahrung.
                                    </p>
                                </div>
                            </div>
                            {/* End Col */}
                        </div>
                        <div className="row align-items-md-center mt-7">
                            <div className="col-md-7 order-md-2 mb-7 mb-md-0">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_326726491.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            {/* End Col */}
                            <div className="col-md-5">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Umfassende Verkaufsvorbereitung</h2>
                                    <p>Der Verkauf Ihrer Immobilie erfordert nicht nur eine marktgerechte Preisgestaltung.</p>
                                    <p>Genauso wichtig ist eine attraktive und ansprechende Darstellung.</p>
                                    <p>Bei uns erhalten Sie daher:</p>
                                </div>
                                {/* End Heading */}
                                {/* List Checked */}
                                <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                                    <li className="list-checked-item">Hochwertige Fotodokumentationen</li>
                                    <li className="list-checked-item">360Grad Rundgänge</li>
                                    <li className="list-checked-item">Drohnenaufnahmen</li>
                                    <li className="list-checked-item">Professionell erstellte Exposés mit allen wichtigen Daten (Grundriss, Grundbuch, Energieausweis)</li>
                                    <li className="list-checked-item">Eintrag Ihrer Immobilie auf unserer Website</li>
                                    <li className="list-checked-item">Eintrag Ihrer Immobilie in wichtigen Immobilienportalen</li>
                                </ul>
                                {/* End List Checked */}
                            </div>
                            {/* End Col */}
                        </div>
                        <div className="row align-items-md-center mt-7">
                            <div className="col-lg-6  mb-7">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_374280445.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Transparenter Maklerauftrag</h2>
                                    <p>Sie erhalten von uns vertraglich zugesicherte Leistungen für die exklusive Vermarktung Ihrer Immobilie deutschlandweit.</p>
                                </div>
                            </div>
                            {/* End Col */}
                        </div>
                        <div className="row align-items-md-center mt-7">
                            <div className="col-md-7 order-md-2 mb-7 mb-md-0">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_198315484.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            {/* End Col */}
                            <div className="col-md-5">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Dokumentenservice für den Immobilienverkauf</h2>
                                    <p>Um eine Immobilie in Deutschland verkaufen zu können, benötigen Sie eine Reihe von Unterlagen, die wir für Sie zusammenstellen.</p>
                                    <p>Inbegriffen in unserem Dokumentenservice sind u.a.:</p>
                                </div>
                                {/* End Heading */}
                                {/* List Checked */}
                                <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                                    <li className="list-checked-item">Grundrisse</li>
                                    <li className="list-checked-item">Grundbuch</li>
                                    <li className="list-checked-item">Unterlagen aus dem Bauarchiv</li>
                                    <li className="list-checked-item">Lageplan</li>
                                    <li className="list-checked-item">Energieausweis</li>
                                    <li className="list-checked-item">Wohnflächenberechnung</li>
                                    <li className="list-checked-item">Aufstellung aller Modernisierung und Sanierungsmaßnahmen</li>
                                    <li className="list-checked-item">Baubeschreibung</li>
                                    <li className="list-checked-item">Nachweis der Gebäudeversicherungen</li>
                                </ul>
                                {/* End List Checked */}
                            </div>
                            {/* End Col */}
                        </div>
                        <div className="row align-items-md-center mt-7">
                            <div className="col-lg-6  mb-7">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_116761406.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Zielgerichtete Vermarktung Ihrer Immobilie</h2>
                                    <p>
                                        Die Strategie zur Vermarktung Ihrer Immobilie stimmen wir mit Ihnen individuell nach den Markt- Erfordernissen und unseren Erfahrungen ab. Ein Verkaufs-Schild
                                        am Haus wird selten von uns zu sehen sein, da die Verkäufer in der Regel die diskrete Vermarktung wünschen.
                                    </p>
                                </div>
                            </div>
                            {/* End Col */}
                        </div>
                        <div className="row align-items-md-center mt-7">
                            <div className="col-md-7 order-md-2 mb-7 mb-md-0">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_111918435.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            {/* End Col */}
                            <div className="col-md-5">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Vertragsverhandlung</h2>
                                    <p>Ist ein potentieller Käufer gefunden übernehmen wir für Sie die Vertragsverhandlung.</p>
                                    <p>Profitieren Sie von unseren jahrelangen Erfahrungen in Vertragsverhandlungen, damit Sie ohne Umwege Ihre Immobilie verkaufen.</p>
                                </div>
                                {/* End Heading */}
                                {/* List Checked */}
                                <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                                    <li className="list-checked-item">prof. Verhandlungsgespräche mit potentiellen Käufern</li>
                                    <li className="list-checked-item">Abstimmung des Kaufvertrages</li>
                                    <li className="list-checked-item">Einholung der Finanzierungsbestätigung</li>
                                    <li className="list-checked-item">Vorbereitung des Notartermins</li>
                                    <li className="list-checked-item">Teilnahme an der Beurkundung des Kaufvertrages</li>
                                </ul>
                                {/* End List Checked */}
                            </div>
                            {/* End Col */}
                        </div>
                        <div className="row align-items-md-center mt-7">
                            <div className="col-lg-6  mb-7">
                                <div className="pe-lg-6" data-aos="fade-up">
                                    {/* Mobile Device */}
                                    <figure className="device-browser">
                                        <div className="device-browser-frame">
                                            <img
                                                className="device-browser-img"
                                                style={{ borderTopRightRadius: '0.5rem', borderTopLeftRadius: '0.5rem' }}
                                                src={`${process.env.PUBLIC_URL + '/img/template-pictures/AdobeStock_18060885.jpeg'}`}
                                                alt="Image Description"
                                            />
                                        </div>
                                    </figure>
                                    {/* End Mobile Device */}
                                </div>
                            </div>
                            <div className="col-md-5 order-md-2">
                                {/* Heading */}
                                <div className="mb-5">
                                    <h2 className="mb-3">Persönliche Nachbetreuung</h2>
                                    <p>Ist die Immobilie verkauft, stehen wir Ihnen weiter unterstützend zur Seite, damit schnellstmöglich die Objektübergabe fachgerecht abwickelt wird.</p>
                                    <p>Wir bereiten das Übergabeprotokoll vor, das auch bei der Ummeldung der Medien, der Versicherungen und bei den Behörden behilflich ist.</p>
                                </div>
                            </div>
                            {/* End Col */}
                        </div>
                    </div>
                    <div className={`${requestSuccessful ? 'd-none' : 'col-lg-4'}`}>
                        {/* Sticky Block */}
                        <div id="stickyBlockStartPointEg3">
                            <div
                                className="js-sticky-block"
                                data-hs-sticky-block-options='{
                          "parentSelector": "#stickyBlockStartPointEg3",
                          "breakpoint": "lg",
                          "startPoint": "#stickyBlockStartPointEg3",
                          "endPoint": "#stickyBlockEndPointEg3",
                          "stickyOffsetTop": 24,
                          "stickyOffsetBottom": 0
                          }'
                            >
                                {/* Card */}
                                <div className="card card-bordered">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center mb-4">
                                            <div className="flex-shrink-0">
                                                <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/img/katrinrauprecht.png'}`} alt="Image Description" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h4 className="card-title">Fragen Sie uns nach weiteren Informationen</h4>
                                            </div>
                                        </div>
                                        {/* End Media */}
                                        {/* Form */}
                                        <form>
                                            <select
                                                className="form-select mb-2"
                                                onChange={(e: any) => setRequest({ ...request, anrede: e.target.value })}
                                                value={request && request.anrede ? request.anrede : 'Frau'}
                                            >
                                                <option value="Anrede">Anrede</option>
                                                <option value="Frau">Frau</option>
                                                <option value="Herr">Herr</option>
                                            </select>
                                            <div className="mb-2">
                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                    Vorname
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fullNamePropertyOverviewContactForm"
                                                    placeholder="Vorname"
                                                    aria-label="Vorname"
                                                    value={request && request.firstName ? request.firstName : ''}
                                                    onChange={(e: any) => setRequest({ ...request, firstName: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                    Nachname
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="fullNamePropertyOverviewContactForm"
                                                    placeholder="Nachname"
                                                    aria-label="Nachname"
                                                    value={request.lastName ? request.lastName : ''}
                                                    onChange={(e: any) => setRequest({ ...request, lastName: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="emailPropertyOverviewContactForm" className="form-label visually-hidden">
                                                    E-Mail
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="emailPropertyOverviewContactForm"
                                                    placeholder="E-Mail"
                                                    aria-label="E-Mail"
                                                    value={request.eMail ? request.eMail : ''}
                                                    onChange={(e: any) => setRequest({ ...request, eMail: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="phoneNumberPropertyOverviewContactForm" className="form-label visually-hidden">
                                                    Telefon
                                                </label>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    id="phoneNumberPropertyOverviewContactForm"
                                                    placeholder="Telefon"
                                                    aria-label="Telefon"
                                                    value={request.telephone ? request.telephone : ''}
                                                    onChange={(e: any) => setRequest({ ...request, telephone: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="questionPropertyOverviewContactForm" className="form-label visually-hidden">
                                                    Anfrage
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    id="questionPropertyOverviewContactForm"
                                                    placeholder="Ich hätte gern mehr Information über..."
                                                    aria-label="Ich hätte gern mehr Information über..."
                                                    rows={4}
                                                    defaultValue={''}
                                                    value={request.customText ? request.customText : ''}
                                                    onChange={(e: any) => setRequest({ ...request, customText: e.target.value })}
                                                />
                                            </div>
                                            <div className="d-grid">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e: any) => {
                                                        ri_homepage_send_request(
                                                            request.anrede,
                                                            request.firstName,
                                                            request.lastName,
                                                            request.eMail,
                                                            request.telephone,
                                                            request.customText,
                                                            null,
                                                            'Immobilienvermittlung'
                                                        );
                                                        setRequestSuccessful(true);
                                                    }}
                                                    disabled={
                                                        request && (!request.telephone || !request.eMail || !request.lastName || !request.firstName || !request.anrede || request.anrede === 'Anrede')
                                                    }
                                                >
                                                    Abschicken
                                                </button>
                                            </div>
                                        </form>
                                        {/* End Form */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="stickyBlockEndPointEg3" />
            </div>
            {/* End Team */}
        </>
    );
};

export default SellPage;

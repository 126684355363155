import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { Action } from '../actions';

export const benit_central_ui_delete_error = (error: {}) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_UI_DELETE_ERROR,
            payload: error,
        });
    };
};

export const benit_central_ui_delete_all_errors = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_UI_DELETE_ALL_ERRORS,
        });
    };
};

export const benit_central_ui_add_errors = (errors: {}[]) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.BENIT_CENTRAL_UI_ADD_ERRORS,
            payload: errors,
        });
    };
};

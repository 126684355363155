import { combineReducers } from 'redux';
import riReducer from '../services/benit/pages/state/reducers';
import benitCentralUIReducer from '../services/benit/central-ui/state/reducers';

const reducers = combineReducers({
    riReducer,
    benitCentralUIReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;

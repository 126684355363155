import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const history = useHistory();
    const location = useLocation();
    return (
        <footer className="">
            <div className="container pb-1 pb-lg-3">
                <div className="row content-space-t-2">
                    <div className="col-lg-3 mb-7 mb-lg-0">
                        {/* Logo */}
                        <div className="mb-5">
                            <a className="navbar-brand" href="%PUBLIC_URL%/index.html" aria-label="Space">
                                <img className="navbar-brand-logo" src={`${process.env.PUBLIC_URL + '/img/LOGO-klein.jpg'}`} alt="Image Description" />
                            </a>
                        </div>
                        {/* End Logo */}
                        {/* List */}
                        <ul className="list-unstyled list-py-1">
                            <li>
                                <a className="link-sm link-dark" href="https://goo.gl/maps/JSPnb1cbceWh1RF2A">
                                    <i className="bi-geo-alt-fill me-1" /> Dorfstr. 42, 16321 Bernau bei Berlin
                                </a>
                            </li>
                            <li>
                                <a className="link-sm link-dark" href="tel:+493338768439">
                                    <i className="bi-telephone-inbound-fill me-1" /> (03338) 768439
                                </a>
                            </li>
                            <li>
                                <a className="link-sm link-dark" href="mailto:info@rauprecht-immobilien.de">
                                    <i className="bi-envelope-fill me-1" /> info@rauprecht-immobilien.de
                                </a>
                            </li>
                        </ul>
                        {/* End List */}
                    </div>
                    {/* End Col */}
                    {/* <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="text-black mb-3">Mitgliedschaft</h5>
                        <ul className="list-unstyled list-py-1 mb-0">
                            <li>
                                <img src={`${process.env.PUBLIC_URL + '/img/template-pictures/ivd_logo.png'}`} width={60} />
                            </li>
                            <li>Katrin Rauprecht ist eingetragenes ivd Mitglied</li>
                        </ul>
                    </div> */}
                    {/* End Col */}
                    <div className="col-sm mb-7 mb-sm-0">
                        <h5 className="text-black mb-3">Auszeichnungen</h5>
                        {/* List */}
                        <ul className="list-unstyled list-py-1 mb-0">
                            <li>
                                <img src={`${process.env.PUBLIC_URL + '/img/template-pictures/auszeichnung_bellevue.png'}`} width={170} />
                            </li>
                        </ul>
                        {/* End List */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm">
                        <h5 className="text-black mb-3">Ausbildung</h5>
                        {/* List */}
                        <ul className="list-unstyled list-py-1 mb-0">
                            <li>
                                <img src={`${process.env.PUBLIC_URL + '/img/template-pictures/dekra_logo_hd.png'}`} width={60} />
                            </li>
                            <li>Katrin Rauprecht ist Immobilienfachwirt IHK und Sachverständige für Immobilienbewertung Standard EFH/ZFH</li>
                        </ul>
                        {/* End List */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm">
                        <h5 className="text-black mb-3">Bewertung</h5>
                        <ul className="list-unstyled list-py-1 mb-5">
                            <li>
                                <iframe
                                    height={160}
                                    width={190}
                                    src="//widget.immobilienscout24.de/anbieter/bewertung/9a4c7b449fb2baffca481f0ffccf396d"
                                    frameBorder={0}
                                    style={{ overflow: 'hidden' }}
                                    marginHeight={0}
                                    marginWidth={0}
                                />
                            </li>
                        </ul>
                        {/* End List */}
                    </div>
                    {/* End Col */}
                </div>
                {/* End Row */}
                <div className="border-top border-black-10 my-4" />
                <div className="row mb-3">
                    <div className="col-sm mb-3 mb-sm-0">
                        {/* Socials */}
                        <ul className="list-inline list-separator list-separator-dark mb-0">
                            <li className="list-inline-item">
                                <Link
                                    to="/impressum"
                                    className={` link-sm link-dark ${location.pathname.startsWith('/impressum') ? 'active text-blue' : null}`}
                                    onClick={(e) => history.push('/impressum')}
                                >
                                    Impressum
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    to="/datenschutz"
                                    className={` link-sm link-dark ${location.pathname.startsWith('/datenschutz') ? 'active text-blue' : null}`}
                                    onClick={(e) => history.push('/datenschutz')}
                                >
                                    Datenschutz
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link to="/agb" className={` link-sm link-dark ${location.pathname.startsWith('/agb') ? 'active text-blue' : null}`} onClick={(e) => history.push('/agb')}>
                                    AGB
                                </Link>
                            </li>
                        </ul>
                        {/* End Socials */}
                    </div>
                    <div className="col-sm-auto">
                        {/* Socials */}
                        <ul className="list-inline mb-0">
                            {/* <li className="list-inline-item">
                                <a className="btn btn-soft-dark btn-xs btn-icon" href="#">
                                    <i className="bi-facebook" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-soft-dark btn-xs btn-icon" href="#">
                                    <i className="bi-google" />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="btn btn-soft-dark btn-xs btn-icon" href="#">
                                    <i className="bi-twitter" />
                                </a>
                            </li> */}
                            <li className="list-inline-item">
                                <a className="btn btn-soft-dark btn-xs btn-icon" href="mailto:info@rauprecht-immobilien.de">
                                    <i className="bi-envelope" />
                                </a>
                            </li>
                            {/* <li className="list-inline-item">
                                <div className="btn-group">
                                    <button
                                        type="button"
                                        className="btn btn-soft-dark btn-xs dropdown-toggle"
                                        id="footerSelectLanguage"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        data-bs-dropdown-animation
                                    >
                                        <span className="d-flex align-items-center">
                                            <img
                                                className="avatar avatar-xss avatar-circle me-2"
                                                src={`${process.env.PUBLIC_URL + '/assets/vendor/flag-icon-css/flags/1x1/us.svg'}`}
                                                alt="Image description"
                                                width={16}
                                            />
                                            <span>English (US)</span>
                                        </span>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="footerSelectLanguage">
                                        <a className="dropdown-item d-flex align-items-center active" href="#">
                                            <img
                                                className="avatar avatar-xss avatar-circle me-2"
                                                src={`${process.env.PUBLIC_URL + '/assets/vendor/flag-icon-css/flags/1x1/us.svg'}`}
                                                alt="Image description"
                                                width={16}
                                            />
                                            <span>English (US)</span>
                                        </a>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img
                                                className="avatar avatar-xss avatar-circle me-2"
                                                src={`${process.env.PUBLIC_URL + '/assets/vendor/flag-icon-css/flags/1x1/de.svg'}`}
                                                alt="Image description"
                                                width={16}
                                            />
                                            <span>Deutsch</span>
                                        </a>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img
                                                className="avatar avatar-xss avatar-circle me-2"
                                                src={`${process.env.PUBLIC_URL + '/assets/vendor/flag-icon-css/flags/1x1/es.svg'}`}
                                                alt="Image description"
                                                width={16}
                                            />
                                            <span>Español</span>
                                        </a>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            <img
                                                className="avatar avatar-xss avatar-circle me-2"
                                                src={`${process.env.PUBLIC_URL + '/assets/vendor/flag-icon-css/flags/1x1/cn.svg'}`}
                                                alt="Image description"
                                                width={16}
                                            />
                                            <span>中文 (繁體)</span>
                                        </a>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                        {/* End Socials */}
                    </div>
                </div>
                {/* Copyright */}
                <div className="w-md-85 text-lg-center mx-lg-auto">
                    <p className="text-black-50 small">© 2024 - Rauprecht Immobilien</p>
                </div>
                {/* End Copyright */}
            </div>
        </footer>
    );
};

export default Footer;

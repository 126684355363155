import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import App from './App';
import './i18n';

console.clear();

ReactDOM.render(
    <Suspense fallback={null}>
        <App />
    </Suspense>,
    document.querySelector('#root')
);

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './state';
import Main from './Main';

//microfrontend-app-2 for lazy component

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Main />
            </BrowserRouter>
        </Provider>
    );
};

export default App;

import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const benit_homepage_get_objects = (id = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const host = window.location.host;

        dispatch({
            type: ActionType.RI_HOMEPAGE_OBJECTS_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `https://${
                host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
            }/api/ri/real-estate/public/objekt${id !== '' ? '?objektId=' + id : ''}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_HOMEPAGE_OBJECTS_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const benit_homepage_get_detail_entry = (id = '', passcode?: string | null, ownerPasscode?: string | null) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const host = window.location.host;

        dispatch({
            type: ActionType.RI_HOMEPAGE_DETAIL_ENTRY_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `https://${
                host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
            }/api/ri/real-estate/public/objekt${id !== '' ? '?objectId=' + id : ''}${passcode ? `&passcode=${passcode}` : ''}${ownerPasscode ? `&ownerPasscode=${ownerPasscode}` : ''}`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_HOMEPAGE_DETAIL_ENTRY_GET_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_homepage_send_request = (
    anrede: string,
    firstName: string,
    lastName: string,
    eMail: string,
    telephone: string,
    customText: string,
    objectId?: string | null,
    contactType?: string,
    street?: string,
    postcode?: string,
    city?: string
) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const host = window.location.host;

        dispatch({
            type: ActionType.RI_HOMEPAGE_DETAIL_ENTRY_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `https://${
                host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
            }/api/ri/real-estate/public/info-request`,
            method: 'post',
            body: { anrede, firstName, lastName, eMail, telephone, customText, objectId, contactType, street, postcode, city },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_HOMEPAGE_REQUEST_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

export const ri_homepage_send_valuation_request = (request: any) => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const host = window.location.host;

        dispatch({
            type: ActionType.RI_HOMEPAGE_DETAIL_ENTRY_GET_PENDING,
        });

        const { doRequest } = useRequest({
            url: `https://${
                host === 'mockups.benit.cloud' || host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'benit.cloud' : 'benit.dev'
            }/api/ri/real-estate/public/valuation-request`,
            method: 'post',
            body: { request },
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.RI_HOMEPAGE_VALUATION_REQUEST_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import fireToast from '../../../benit/central-ui/methods/fireToast';

const PropertyManagementPage = () => {
    const [request, setRequest] = useState<{ [x: string]: string }>({ anrede: 'Anrede', firstName: '', lastName: '', eMail: '', telephone: '', customText: '' });
    const [requestSuccessful, setRequestSuccessful] = useState(false);

    const { ri_homepage_send_request } = useActions();

    useEffect(() => {
        // @ts-ignore
        new HSStickyBlock('.js-sticky-block', {
            // @ts-ignore
            targetSelector: '#header',
        });
        // @ts-ignore
        new HsNavScroller('.js-nav-scroller');
    }, []);

    useEffect(() => {
        if (requestSuccessful) {
            //@ts-ignore
            // const liveToast = new bootstrap.Toast(document.querySelector('#liveToast'));
            // liveToast.show();
            fireToast('success', `Anfrage erfolgreich versendet`);
        }
    }, [requestSuccessful]);

    return (
        <>
            <div>
                <div className="container">
                    {/* Heading */}
                    <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                        <span className="text-cap">Wir verwalten Ihre Immobilie</span>
                        <h2>Wir kümmern uns um Ihr Objekt, damit Sie sich um die wirklich wichtigen Dinge kümmern können.</h2>
                    </div>
                    {/* End Heading */}
                    <div className={`${'row'}`}>
                        <div className={`${requestSuccessful ? 'col-lg-12' : 'col-lg-8'}`}>
                            <div className="row justify-content-lg-center">
                                <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                                    {/* Icon Blocks */}
                                    <div className="d-flex pe-md-5">
                                        <div className="flex-shrink-0">
                                            <div className="svg-icon text-primary">
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                                                        fill="#035A4B"
                                                    />
                                                    <path
                                                        opacity="0.3"
                                                        d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                                                        fill="#035A4B"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4>Wir helfen den besten Mieter für Sie zu finden</h4>
                                            <p>Aufbereitung des Mietobjektes in Form eines aussagekräftigen Exposé</p>
                                            <p>Zusammenstellung der für die Neuvermietung notwendigen Unterlagen</p>
                                            <p>Werbung für die Neuvermietung regional und web-basiert</p>
                                            <p>Besichtigung mit Mietinteressenten</p>
                                            <p>Aufbereitung der Bewerbungsunterlagen als Entscheidungsgrundlage der Neuvermietung</p>
                                        </div>
                                    </div>
                                    {/* End Icon Blocks */}
                                </div>
                                <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                                    {/* Icon Blocks */}
                                    <div className="d-flex ps-md-5">
                                        <div className="flex-shrink-0">
                                            <div className="svg-icon text-primary">
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        opacity="0.3"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M4.85714 1H11.7364C12.0911 1 12.4343 1.12568 12.7051 1.35474L17.4687 5.38394C17.8057 5.66895 18 6.08788 18 6.5292V19.0833C18 20.8739 17.9796 21 16.1429 21H4.85714C3.02045 21 3 20.8739 3 19.0833V2.91667C3 1.12612 3.02045 1 4.85714 1ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z"
                                                        fill="#035A4B"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M6.85714 3H14.7364C15.0911 3 15.4343 3.12568 15.7051 3.35474L20.4687 7.38394C20.8057 7.66895 21 8.08788 21 8.5292V21.0833C21 22.8739 20.9796 23 19.1429 23H6.85714C5.02045 23 5 22.8739 5 21.0833V4.91667C5 3.12612 5.02045 3 6.85714 3ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z"
                                                        fill="#035A4B"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4>Verwaltung der Mietbereiche</h4>
                                            <p>Ausführung und Kontrolle aller Kosten des Mietobjektes</p>
                                            <p>Entgegennahme und Kontrolle aller Mietzahlungen des Mietobjektes</p>
                                            <p>Erstellung eines Jahresabschusses für die Eigentümer des Mietobjektes</p>
                                            <p>Erstellung der Betriebskostenabrechnung, Anpassung der Vorauszahlungen der Betriebskosten und Kontrolle der Zahlungen der Mieter</p>
                                            <p>Kontrolle und Sanktionen bei Verstößen gegen Mietvertrag oder Hausordnung</p>
                                            <p>Wohnungsübergabe/Übernahme bei Mieterwechsel</p>
                                        </div>
                                    </div>
                                    {/* End Icon Blocks */}
                                </div>
                                <div className="w-100" />
                                <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                                    {/* Icon Blocks */}
                                    <div className="d-flex pe-md-5">
                                        <div className="flex-shrink-0">
                                            <div className="svg-icon text-primary">
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M3 3V17H7V21H15V9H20V3H3Z" fill="#035A4B" />
                                                    <path
                                                        d="M20 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V21C21 21.6 20.6 22 20 22ZM19 4H4V8H19V4ZM6 18H4V20H6V18ZM6 14H4V16H6V14ZM6 10H4V12H6V10ZM10 18H8V20H10V18ZM10 14H8V16H10V14ZM10 10H8V12H10V10ZM14 18H12V20H14V18ZM14 14H12V16H14V14ZM14 10H12V12H14V10ZM19 14H17V20H19V14ZM19 10H17V12H19V10Z"
                                                        fill="#035A4B"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4>Mietpreisermittlung und Mietvertragsgestaltung nach aktueller Rechtssprechung</h4>
                                            <p>Durchsetzung von Mietpreisanpassungen</p>
                                            <p>Anpassung der Betriebskostenvorrauszahlungen</p>
                                            <p>Rechtsgültige Verfahren zur Kündigung des Mietvertages</p>
                                            <p>Wohnungsabnahme und -übergabeprotokoll</p>
                                            <p>Kautionsabrechnung</p>
                                            <p>Betriebskostenabrechnung zum Vertragsende</p>
                                        </div>
                                    </div>
                                    {/* End Icon Blocks */}
                                </div>
                                <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-7">
                                    {/* Icon Blocks */}
                                    <div className="d-flex ps-md-5">
                                        <div className="flex-shrink-0">
                                            <div className="svg-icon text-primary">
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        opacity="0.3"
                                                        d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z"
                                                        fill="#035A4B"
                                                    />
                                                    <path
                                                        d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z"
                                                        fill="#035A4B"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4>Schadensvermeidung und Instandhaltung/Instandsetzung Ihres Wohneigentums</h4>
                                            <p>Kontakte zu regionalen Handwerksbetrieben für alle Gewerke</p>
                                            <p>Gartenpflege und Bewirtschaftung</p>
                                        </div>
                                    </div>
                                    {/* End Icon Blocks */}
                                </div>
                                <div className="w-100" />
                                <div className="col-md-6 col-lg-5 mb-3 mb-md-5 mb-lg-0">
                                    {/* Icon Blocks */}
                                    <div className="d-flex pe-md-5">
                                        <div className="flex-shrink-0">
                                            <div className="svg-icon text-primary">
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        opacity="0.3"
                                                        d="M15.8 11.4H6C5.4 11.4 5 11 5 10.4C5 9.80002 5.4 9.40002 6 9.40002H15.8C16.4 9.40002 16.8 9.80002 16.8 10.4C16.8 11 16.3 11.4 15.8 11.4ZM15.7 13.7999C15.7 13.1999 15.3 12.7999 14.7 12.7999H6C5.4 12.7999 5 13.1999 5 13.7999C5 14.3999 5.4 14.7999 6 14.7999H14.8C15.3 14.7999 15.7 14.2999 15.7 13.7999Z"
                                                        fill="#035A4B"
                                                    />
                                                    <path
                                                        d="M18.8 15.5C18.9 15.7 19 15.9 19.1 16.1C19.2 16.7 18.7 17.2 18.4 17.6C17.9 18.1 17.3 18.4999 16.6 18.7999C15.9 19.0999 15 19.2999 14.1 19.2999C13.4 19.2999 12.7 19.2 12.1 19.1C11.5 19 11 18.7 10.5 18.5C10 18.2 9.60001 17.7999 9.20001 17.2999C8.80001 16.8999 8.49999 16.3999 8.29999 15.7999C8.09999 15.1999 7.80001 14.7 7.70001 14.1C7.60001 13.5 7.5 12.8 7.5 12.2C7.5 11.1 7.7 10.1 8 9.19995C8.3 8.29995 8.79999 7.60002 9.39999 6.90002C9.99999 6.30002 10.7 5.8 11.5 5.5C12.3 5.2 13.2 5 14.1 5C15.2 5 16.2 5.19995 17.1 5.69995C17.8 6.09995 18.7 6.6 18.8 7.5C18.8 7.9 18.6 8.29998 18.3 8.59998C18.2 8.69998 18.1 8.69993 18 8.79993C17.7 8.89993 17.4 8.79995 17.2 8.69995C16.7 8.49995 16.5 7.99995 16 7.69995C15.5 7.39995 14.9 7.19995 14.2 7.19995C13.1 7.19995 12.1 7.6 11.5 8.5C10.9 9.4 10.5 10.6 10.5 12.2C10.5 13.3 10.7 14.2 11 14.9C11.3 15.6 11.7 16.1 12.3 16.5C12.9 16.9 13.5 17 14.2 17C15 17 15.7 16.8 16.2 16.4C16.8 16 17.2 15.2 17.9 15.1C18 15 18.5 15.2 18.8 15.5Z"
                                                        fill="#035A4B"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4>WEG-Verwaltung</h4>
                                            <p>Verwaltung des Gemeinschaftseigentums der WEG nach den Grundsätzen des WEG-Rechts</p>
                                        </div>
                                    </div>
                                    {/* End Icon Blocks */}
                                </div>
                                <div className="col-md-6 col-lg-5">
                                    {/* Icon Blocks */}
                                    <div className="d-flex ps-md-5">
                                        <div className="flex-shrink-0">
                                            <div className="svg-icon text-primary">
                                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20 19H4C2.9 19 2 18.1 2 17H22C22 18.1 21.1 19 20 19Z" fill="#035A4B" />
                                                    <path opacity="0.3" d="M20 5H4C3.4 5 3 5.4 3 6V17H21V6C21 5.4 20.6 5 20 5Z" fill="#035A4B" />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M14.9167 6.83334H9.91666C9.18899 6.83334 8.66666 7.37744 8.66666 8.08334V13.9167C8.66666 14.6226 9.18899 15.1667 9.91666 15.1667H14.9167C15.1841 15.1667 15.3333 15.0112 15.3333 14.75V14.3333H10.3333C10.1032 14.3333 9.91665 14.1468 9.91665 13.9167C9.91665 13.6866 10.1032 13.5 10.3333 13.5H15.3333V7.25001C15.3333 6.9888 15.1841 6.83334 14.9167 6.83334Z"
                                                        fill="#035A4B"
                                                    />
                                                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x={8} y={6} width={8} height={10}>
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M14.9167 6.83334H9.91666C9.18899 6.83334 8.66666 7.37744 8.66666 8.08334V13.9167C8.66666 14.6226 9.18899 15.1667 9.91666 15.1667H14.9167C15.1841 15.1667 15.3333 15.0112 15.3333 14.75V14.3333H10.3333C10.1032 14.3333 9.91665 14.1468 9.91665 13.9167C9.91665 13.6866 10.1032 13.5 10.3333 13.5H15.3333V7.25001C15.3333 6.9888 15.1841 6.83334 14.9167 6.83334Z"
                                                            fill="white"
                                                        />
                                                    </mask>
                                                    <g mask="url(#mask0)"></g>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h4>Offene Verwaltung</h4>
                                            <p>
                                                Die Vermieter und Wohnungseigentümer haben über einen Online-Zugang zum Immobilien-Portal der Fa. Rauprecht Immobilien GmbH die Möglichkeit alle
                                                Geschäftsvorgänge der Verwaltung zu sehen und zu prüfen.
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Icon Blocks */}
                                </div>
                            </div>
                            {/* End Icon Blocks */}
                            {/* Features */}
                            <div className="container content-space-t-2 content-space-b-2 content-space-b-lg-3">
                                {/* Heading */}
                                <div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                                    <span className="text-cap">Rauprecht Immobilien Eigentümerportal</span>
                                    <h2>Alle Informationen für Sie aufbereitet, 24 Stunden am Tag, 7 Tage die Woche.</h2>
                                </div>
                                {/* End Heading */}
                                <div className="row align-items-md-center mt-10">
                                    <div className="col-lg-6  mb-7">
                                        <div className="position-relative mx-auto" data-aos="fade-up">
                                            {/* Mobile Device */}
                                            <figure className="device-mobile mx-auto">
                                                <div className="device-mobile-frame">
                                                    <img className="device-mobile-img" src={`${process.env.PUBLIC_URL + '/img/mobile-buchungen.png'}`} alt="Image Description" />
                                                </div>
                                            </figure>
                                            {/* End Mobile Device */}
                                        </div>
                                    </div>
                                    <div className="col-md-5 order-md-2">
                                        {/* Heading */}
                                        <div className="mb-5">
                                            <h2 className="mb-3">Online-Anwendung des Immobilien-Portals</h2>
                                        </div>
                                        {/* End Heading */}
                                        {/* List Checked */}
                                        <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                                            <li className="list-checked-item">Darstellung des aktuellen Buchungsstandes des Verwalterkontos</li>
                                            <li className="list-checked-item">Anzeige alle Belege des Objektes</li>
                                            <li className="list-checked-item">Abruf aller Informationen zu den Belegen der Buchungen</li>
                                        </ul>
                                        {/* End List Checked */}
                                    </div>
                                    {/* End Col */}
                                </div>
                                {/* End Row */}
                                <div className="row align-items-md-center">
                                    <div className="col-md-7 order-md-2 mb-7 mb-md-0">
                                        <div className="w-sm-75 mx-sm-auto" data-aos="fade-up">
                                            {/* Card */}
                                            <div className="w-75 mx-auto">
                                                {/* Mobile Device */}
                                                <figure className="device-mobile mx-auto">
                                                    <div className="device-mobile-frame">
                                                        <img className="device-mobile-img" src={`${process.env.PUBLIC_URL + '/img/mobile-weg.png'}`} alt="Image Description" />
                                                    </div>
                                                </figure>
                                                {/* End Mobile Device */}
                                            </div>
                                            {/* End Card */}
                                        </div>
                                    </div>
                                    {/* End Col */}
                                    <div className="col-md-5">
                                        {/* Heading */}
                                        <div className="mb-5">
                                            <h2 className="mb-3">Chat-Programm zum Informationsaustausch der WEG</h2>
                                        </div>
                                        {/* End Heading */}
                                        {/* List Checked */}
                                        <ul className="list-checked list-checked-soft-bg-primary list-checked-lg">
                                            <li className="list-checked-item">Online-Bibliothek aller WEG-Beschlüsse</li>
                                            <li className="list-checked-item">Archiv aller WEG-Sitzungsprotokolle</li>
                                            <li className="list-checked-item">Terminabstimmungen der WEG</li>
                                        </ul>
                                        {/* End List Checked */}
                                    </div>
                                    {/* End Col */}
                                </div>
                                {/* End Row */}
                            </div>
                            {/* End Features */}
                        </div>
                        <div className={`${requestSuccessful ? 'd-none' : 'col-lg-4'}`}>
                            {/* Sticky Block */}
                            <div id="stickyBlockStartPointEg3">
                                <div
                                    className="js-sticky-block"
                                    data-hs-sticky-block-options='{
                          "parentSelector": "#stickyBlockStartPointEg3",
                          "breakpoint": "lg",
                          "startPoint": "#stickyBlockStartPointEg3",
                          "endPoint": "#stickyBlockEndPointEg3",
                          "stickyOffsetTop": 24,
                          "stickyOffsetBottom": 0
                          }'
                                >
                                    {/* Card */}
                                    <div className="card card-bordered">
                                        <div className="card-body">
                                            <div className="d-flex align-items-center mb-4">
                                                <div className="flex-shrink-0">
                                                    <img className="avatar avatar-circle" src={`${process.env.PUBLIC_URL + '/img/uwe_rauprecht.png'}`} alt="Image Description" />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h4 className="card-title">Fragen Sie uns nach weiteren Informationen</h4>
                                                </div>
                                            </div>
                                            {/* End Media */}
                                            {/* Form */}
                                            <form>
                                                <select
                                                    className="form-select mb-2"
                                                    onChange={(e: any) => setRequest({ ...request, anrede: e.target.value })}
                                                    value={request && request.anrede ? request.anrede : 'Frau'}
                                                >
                                                    <option value="Anrede">Anrede</option>
                                                    <option value="Frau">Frau</option>
                                                    <option value="Herr">Herr</option>
                                                </select>
                                                <div className="mb-2">
                                                    <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                        Vorname
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullNamePropertyOverviewContactForm"
                                                        placeholder="Vorname"
                                                        aria-label="Vorname"
                                                        value={request && request.firstName ? request.firstName : ''}
                                                        onChange={(e: any) => setRequest({ ...request, firstName: e.target.value })}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="fullNamePropertyOverviewContactForm" className="form-label visually-hidden">
                                                        Nachname
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="fullNamePropertyOverviewContactForm"
                                                        placeholder="Nachname"
                                                        aria-label="Nachname"
                                                        value={request.lastName ? request.lastName : ''}
                                                        onChange={(e: any) => setRequest({ ...request, lastName: e.target.value })}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="emailPropertyOverviewContactForm" className="form-label visually-hidden">
                                                        E-Mail
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="emailPropertyOverviewContactForm"
                                                        placeholder="E-Mail"
                                                        aria-label="E-Mail"
                                                        value={request.eMail ? request.eMail : ''}
                                                        onChange={(e: any) => setRequest({ ...request, eMail: e.target.value })}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="phoneNumberPropertyOverviewContactForm" className="form-label visually-hidden">
                                                        Telefon
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        className="form-control"
                                                        id="phoneNumberPropertyOverviewContactForm"
                                                        placeholder="Telefon"
                                                        aria-label="Telefon"
                                                        value={request.telephone ? request.telephone : ''}
                                                        onChange={(e: any) => setRequest({ ...request, telephone: e.target.value })}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="questionPropertyOverviewContactForm" className="form-label visually-hidden">
                                                        Anfrage
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        id="questionPropertyOverviewContactForm"
                                                        placeholder="Ich hätte gern mehr Information über..."
                                                        aria-label="Ich hätte gern mehr Information über..."
                                                        rows={4}
                                                        defaultValue={''}
                                                        value={request.customText ? request.customText : ''}
                                                        onChange={(e: any) => setRequest({ ...request, customText: e.target.value })}
                                                    />
                                                </div>
                                                <div className="d-grid">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e: any) => {
                                                            ri_homepage_send_request(
                                                                request.anrede,
                                                                request.firstName,
                                                                request.lastName,
                                                                request.eMail,
                                                                request.telephone,
                                                                request.customText,
                                                                null,
                                                                'Immobilienverwaltung'
                                                            );
                                                            setRequestSuccessful(true);
                                                        }}
                                                        disabled={
                                                            request &&
                                                            (!request.telephone || !request.eMail || !request.lastName || !request.firstName || !request.anrede || request.anrede === 'Anrede')
                                                        }
                                                    >
                                                        Abschicken
                                                    </button>
                                                </div>
                                            </form>
                                            {/* End Form */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="stickyBlockEndPointEg3" />
                </div>
            </div>
        </>
    );
};

export default PropertyManagementPage;

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

const AboutUsPage = () => {
    return (
        <>
            {/* Team */}
            <div className="container">
                {/* Heading */}
                <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
                    <span className="d-block small font-weight-bold text-cap mb-2">Rauprecht Immobilien</span>
                    <h2>Immer für Sie da</h2>
                </div>
                {/* End Heading */}
                <div className="row mb-7">
                    <div className="col-sm-6 col-md-6 mb-5 mb-lg-0">
                        {/* Team */}
                        <img className="card-img rounded-2" src={`${process.env.PUBLIC_URL + '/assets/img/400x500/img28.jpg'}`} alt="Image Description" />
                        <div className="card card-sm text-center mt-n7 mx-3">
                            <div className="card-body">
                                <h4 className="card-title">Katrin Rauprecht</h4>
                                <p className="card-text">Geschäftsführerin, Immobilienfachwirtin</p>
                            </div>
                        </div>
                        {/* End Team */}
                    </div>
                    {/* End Col */}
                    <div className="col-sm-6 col-md-6 mb-5 mb-lg-0">
                        {/* Team */}
                        <img className="card-img rounded-2" src={`${process.env.PUBLIC_URL + '/assets/img/400x500/img29.jpg'}`} alt="Image Description" />
                        <div className="card card-sm text-center mt-n7 mx-3">
                            <div className="card-body">
                                <h4 className="card-title">Uwe Rauprecht</h4>
                                <p className="card-text">Geschäftsführer, Diplom- Ingenieur</p>
                            </div>
                        </div>
                        {/* End Team */}
                    </div>
                    {/* End Col */}
                </div>
                {/* End Row */}
            </div>
            {/* End Team */}
        </>
    );
};

export default AboutUsPage;

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useActions } from '../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';

const SitemapPage = () => {
    const host = window.location.host;

    const adobePhotos = [
        {
            fileName: 'AdobeStock_295018894.jpeg',
            placement: 'Startseite',
            photograph: 'Robert Kneschke',
            adobeStockNr: '#295018894',
            adobeLink: 'https://stock.adobe.com/de/images/familie-und-haus-als-symbol-fur-traumhaus/295018894',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_95775043.jpeg',
            placement: 'Verwaltung',
            photograph: 'Tiberius Gracchus',
            adobeStockNr: '#95775043',
            adobeLink: 'https://stock.adobe.com/de/images/altbau-und-baume-in-deutschland/95775043',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_149567561.jpeg',
            placement: 'Verwaltung',
            photograph: 'pixelkorn',
            adobeStockNr: '#149567561',
            adobeLink: 'https://stock.adobe.com/de/images/mietvertrag/149567561',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_185260488.jpeg',
            placement: 'Verwaltung',
            photograph: 'bubbers',
            adobeStockNr: '#185260488',
            adobeLink:
                'https://stock.adobe.com/de/images/wooden-white-house-toy-and-construction-tools-on-wooden-background-with-copy-space-real-estate-concept-new-house-concept-finance-loan-business-concept-repair-maintenance-concept/185260488',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_100701562.jpeg',
            placement: 'Verwaltung',
            photograph: 'Coloures-Pic',
            adobeStockNr: '#100701562',
            adobeLink: 'https://stock.adobe.com/de/images/akte-mit-hausverwaltung/100701562',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_197360388.jpeg',
            placement: 'Verwaltung',
            photograph: 'stokkete',
            adobeStockNr: '#197360388',
            adobeLink: 'https://stock.adobe.com/de/images/real-estate-mortgage-loans-and-paperwork/197360388',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_27898375.jpeg',
            placement: 'Service',
            photograph: 'ehrenberg-bilder',
            adobeStockNr: '#27898375',
            adobeLink: 'https://stock.adobe.com/de/images/wohnbau/27898375',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_85168165.jpeg',
            placement: 'Service',
            photograph: 'Aldeca Productions',
            adobeStockNr: '#85168165',
            adobeLink: 'https://stock.adobe.com/de/images/mani-consegna-chiavi-fazenda/85168165',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_330111619.jpeg',
            placement: 'Service',
            photograph: 'stokkete',
            adobeStockNr: '#330111619',
            adobeLink: 'https://stock.adobe.com/de/images/home-renovation-and-relocation/330111619',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_182154937.jpeg',
            placement: 'Service',
            photograph: 'maho',
            adobeStockNr: '#182154937',
            adobeLink: 'https://stock.adobe.com/de/images/notarsiegel-mit-bauplan/182154937',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_453026224.jpeg',
            placement: 'Service',
            photograph: 'Natee Meepian',
            adobeStockNr: '#453026224',
            adobeLink:
                'https://stock.adobe.com/de/images/legal-consultants-notary-or-justice-lawyer-discussing-contract-document-on-desk-with-client-customer-in-courtroom-office-business-justice-law-insurance-legal-service-buy-and-sell-house-concept/453026224',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_302691791.jpeg',
            placement: 'Service',
            photograph: 'Tinnakorn',
            adobeStockNr: '#302691791',
            adobeLink:
                'https://stock.adobe.com/de/images/house-model-and-coin-calculator-on-table-for-finance-banking-concept-calculating-mortgage-saving-money-for-buy-a-new-house-and-loan-for-plan-business-investment-for-real-estate/302691791',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_238354051.jpeg',
            placement: 'Service',
            photograph: 'fotomek',
            adobeStockNr: '#238354051',
            adobeLink: 'https://stock.adobe.com/de/images/3d-illustration-weisses-mannchen-mit-lupe-haus/238354051',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_34548850.jpeg',
            placement: 'Über uns',
            photograph: 'tina7si',
            adobeStockNr: '#34548850',
            adobeLink: 'https://stock.adobe.com/de/images/mietvertrag-wohnungsubergabe-protokoll-schlussel-und-stift/34548850',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_56486242.jpeg',
            placement: 'Über uns',
            photograph: 'hiphoto39',
            adobeStockNr: '#56486242',
            adobeLink: 'https://stock.adobe.com/de/images/the-home-care/56486242',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_384789070.jpeg',
            placement: 'Über uns',
            photograph: 'ronstik',
            adobeStockNr: '#384789070',
            adobeLink: 'https://stock.adobe.com/de/images/hand-with-pencil-on-cadastral-map-choose-and-buy-a-building-plot-for-house-construction/384789070',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_111918435.jpeg',
            placement: 'Vermittlung',
            photograph: 'maho',
            adobeStockNr: '#111918435',
            adobeLink: 'https://stock.adobe.com/de/images/vertragsabschluss-mit-notarsiegel/111918435',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_18060885.jpeg',
            placement: 'Vermittlung',
            photograph: 'Sashkin',
            adobeStockNr: '#18060885',
            adobeLink: 'https://stock.adobe.com/de/images/insurance-concept-house-under-umbrella/18060885',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_116761406.jpeg',
            placement: 'Vermittlung',
            photograph: 'Alexander Limbach',
            adobeStockNr: '#116761406',
            adobeLink: 'https://stock.adobe.com/de/images/target-arrows-house/116761406',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_198315484.jpeg',
            placement: 'Vermittlung',
            photograph: 'Eisenhans',
            adobeStockNr: '#198315484',
            adobeLink: 'https://stock.adobe.com/de/images/energieausweis-vor-einem-mehrfamilienhaus/198315484',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_374280445.jpeg',
            placement: 'Vermittlung',
            photograph: 'deagreez',
            adobeStockNr: '#374280445',
            adobeLink:
                'https://stock.adobe.com/de/images/cropped-close-up-view-of-two-male-hands-shaking-over-table-desk-signed-corporate-contact-assignment-car-life-insurance-service-in-light-white-interior-work-place-station-indoors/374280445',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_326726491.jpeg',
            placement: 'Vermittlung',
            photograph: 'snowing12',
            adobeStockNr: '#326726491',
            adobeLink: 'https://stock.adobe.com/de/images/real-estate-agent-broker-or-house-developer-showing-contract-for-buying-house-agreement-to-consultant-employee/326726491',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_385522353.jpeg',
            placement: 'Vermittlung',
            photograph: 'ronstik',
            adobeStockNr: '#385522353',
            adobeLink: 'https://stock.adobe.com/de/images/searching-building-plot-for-family-house-construction-hand-with-magnifier-on-cadastre-map/385522353',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_42429022.jpeg',
            placement: 'Startseite',
            photograph: 'thingamajiggs',
            adobeStockNr: '#42429022',
            adobeLink: 'https://stock.adobe.com/de/images/checkliste-hauskauf/42429022',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_42678225.jpeg',
            placement: 'Startseite',
            photograph: 'lassedesignen',
            adobeStockNr: '#42678225',
            adobeLink: 'https://stock.adobe.com/de/images/dream-house/42678225',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_60365592.jpeg',
            placement: 'Startseite',
            photograph: 'Zerbor',
            adobeStockNr: '#60365592',
            adobeLink: 'https://stock.adobe.com/de/images/aktenordner-mit-der-beschriftung-miete-und-nebenkosten/60365592',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_153888363.jpeg',
            placement: 'Startseite',
            photograph: 'js-photo',
            adobeStockNr: '#153888363',
            adobeLink: 'https://stock.adobe.com/de/images/einfamilienhaus-wohnhaus-mit-garten/153888363',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_75137103.jpeg',
            placement: 'Startseite',
            photograph: 'ostap25',
            adobeStockNr: '#75137103',
            adobeLink: 'https://stock.adobe.com/de/images/living-room-classic-style/75137103',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_67387593.jpeg',
            placement: 'Startseite',
            photograph: 'ostap25',
            adobeStockNr: '#67387593',
            adobeLink: 'https://stock.adobe.com/de/images/living-room-art-deco-style-classic-style/67387593?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_87245615.jpeg',
            placement: 'Startseite',
            photograph: '3DarcaStudio',
            adobeStockNr: '#87245615',
            adobeLink: 'https://stock.adobe.com/de/images/soggiorno-con-vista-su-giardino/87245615?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_140888955.jpeg',
            placement: 'Startseite',
            photograph: '2mmedia',
            adobeStockNr: '#140888955',
            adobeLink: 'https://stock.adobe.com/de/images/modern-living-room-in-townhouse-3d-rendering/140888955?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_154967849.jpeg',
            placement: 'Startseite',
            photograph: '2mmedia',
            adobeStockNr: '#154967849',
            adobeLink: 'https://stock.adobe.com/de/images/modern-nordic-kitchen-in-loft-apartment-3d-rendering/154967849?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_157561009.jpeg',
            placement: 'Startseite',
            photograph: 'XtravaganT',
            adobeStockNr: '#157561009',
            adobeLink: 'https://stock.adobe.com/de/images/living-room-in-minimalist-design/157561009?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_166811045.jpeg',
            placement: 'Startseite',
            photograph: 'Luke Cartledge',
            adobeStockNr: '#166811045',
            adobeLink: 'https://stock.adobe.com/de/images/a-luxury-mansion-living-room-with-feature-fireplace/166811045?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_194460411.jpeg',
            placement: 'Startseite',
            photograph: '2mmedia',
            adobeStockNr: '#194460411',
            adobeLink: 'https://stock.adobe.com/de/images/modern-living-room-in-townhouse-3d-rendering/194460411?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_225809723.jpeg',
            placement: 'Startseite',
            photograph: 'Vadim Andrushchenko',
            adobeStockNr: '#225809723',
            adobeLink: 'https://stock.adobe.com/de/images/interior-of-modern-living-room-3d-rendering/225809723?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_246660040.jpeg',
            placement: 'Startseite',
            photograph: 'Michael',
            adobeStockNr: '#246660040',
            adobeLink: 'https://stock.adobe.com/de/images/elegant-style-parisian-interior-living-room/246660040?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_281395932.jpeg',
            placement: 'Startseite',
            photograph: 'Vadim Andrushchenko',
            adobeStockNr: '#281395932',
            adobeLink: 'https://stock.adobe.com/de/images/modern-interior-design-of-house-kitchen-living-room-with-sofa-staircase-3d-rendering/281395932?prev_url=detail',
            license: 'Standard',
        },
        {
            fileName: 'AdobeStock_364521317.jpeg',
            placement: 'Startseite',
            photograph: 'Christian Hillebrand',
            adobeStockNr: '#364521317',
            adobeLink: 'https://stock.adobe.com/de/images/view-inside-modern-luxury-attic-loft-apartment-3d-rendering/364521317?prev_url=detail',
            license: 'Standard',
        },
    ];

    return (
        <>
            {/* Team */}
            <div className="container">
                {/* Heading */}
                <div className="w-md-80 w-lg-50 text-center mx-md-auto">
                    <h2>Impressum/Rechtliches</h2>
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">Rauprecht Immobilien GmbH</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">Dorfstr. 42</li>
                            <li className="list-item mb-5">16321 Bernau bei Berlin</li>
                            <li className="list-item">Tel: 03338 768439</li>
                            <li className="list-item mb-5">Fax: 03338 319360</li>
                            <li className="list-item mb-5">E-Mail: info@rauprecht-immobilien.de</li>
                            <li className="list-item mb-5">Geschäftsführer: Katrin Rauprecht, Dipl. Ing. Uwe Rauprecht</li>
                            <li className="list-item mb-5">Berufsaufsichtsbehörde: Stadtverwaltung Bernau, Bürgermeisterstr. 25, 16321 Bernau</li>
                            <li className="list-item">Betriebshaftpflichtversicherung: Nürnberger Versicherung, 90334 Nürnberg</li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">Handelsregister</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">Amtsgericht Frankfurt (Oder)</li>
                            <li className="list-item">HRB 14209FF</li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">USt.-Id.-Nr.:</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">DE 159 980 424</li>
                            <li className="list-item">Finanzamt Eberswalde</li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">Verbraucherinformation</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">Alternative Streitbeilegung gemäß Artikel 14 Absatz 1 ODR-VO und § 36 VSBG:</li>
                            <li className="list-item">
                                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS-Plattform) bereit, die Sie unter{' '}
                                <a href="https://ec.europa.eu/consumers/odr/" target="_blank">
                                    https://ec.europa.eu/consumers/odr/
                                </a>{' '}
                                erreichen.
                            </li>
                            <li className="list-item">
                                Die Rauprecht Immobilien GmbH nimmt nicht an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil. E-Mail: info@rauprecht-immobilien.de
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                {/* <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">Verbraucherinformation gemäß § 36 VSBG</h3>
                        </div>
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Der Immobilienverband Deutschland IVD e.V. hat mit Beteiligung des Verbraucherverbandes Verband Privater Bauherren e.V. (VPB) eine Schlichtungsstelle nach Maßgabe des
                                Verbraucherstreitbeilegungsgesetzes (VSBG) eingerichtet.
                            </li>
                            <li className="list-item">
                                Vor der Schlichtungsstelle können u. a. Streitigkeiten zwischen Verbrauchern und Mitgliedern des IVD in einem außergerichtlichen Schlichtungsverfahren beigelegt werden.
                            </li>
                            <li className="list-item">
                                Die Firma Rauprecht Immobilien GmbH ist Mitglied im IVD und nimmt an einem Schlichtungsverfahren beim Ombudsmann Immobilien IVD/VPB – Grunderwerb und -verwaltung teil.
                            </li>
                            <li className="list-item">
                                Die Anschrift der Schlichtungsstelle lautet: Ombudsmann Immobilien IVD/VPB –Grunderwerb und -verwaltung, Littenstraße 10, 10179 Berlin. Weitere Informationen zur
                                Schlichtungsstelle (z.B. weitere Kommunikationsdaten, Verfahrensordnung) erhalten
                            </li>
                            <li className="list-item">
                                Sie unter{' '}
                                <a href="http://www.ombudsmann-immobilien.net" target="_blank" rel="noreferrer">
                                    http://www.ombudsmann-immobilien.net
                                </a>
                                .
                            </li>
                            <li className="list-item">An Schlichtungsverfahren bei anderen Schlichtungsstellen nimmt die Firma Rauprecht Immobilien GmbH grundsätzlich nicht teil.</li>
                            <li className="list-item">Verantwortlich für den Bereich Immobilienverkauf und Vermietung: Katrin Rauprecht - Immobilienfachwirtin IHK</li>
                            <li className="list-item">Verantwortlich für den Bereich Immobilienverwaltung: Dipl. Ing. Uwe Rauprecht</li>
                        </ul>
                    </div>
                </div> */}
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">Haftungshinweis</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">
                                Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                                verantwortlich.
                            </li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h3 className="mb-3">Bildrechte</h3>
                        </div>
                        {/* List Checked */}
                        <ul className="list-unstyled list-pointer">
                            <li className="list-item">Diese Website verwendet zum Teil bei adobe.stock lizenzierte (Standardlizenz) Fotos sowie eigene Fotos.</li>
                            <li className="list-item">Die Rechte liegen bei den jeweiligen Fotografen. Eine Nutzung ohne Zustimmung der jeweiligen Rechteinhaber ist nicht zulässig.</li>
                        </ul>
                        {/* End List Checked */}
                    </div>
                    {/* End Col */}
                </div>
                <div className="row align-items-md-center mt-7">
                    <div className="col-md-12">
                        <div className="mb-5">
                            <h4 className="mb-3">Bildnachweis adobe.stock</h4>
                        </div>
                        <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-5 mb-5">
                            {adobePhotos
                                ? adobePhotos.map((photo: any) => {
                                      return (
                                          <div className="col mb-3 mr-3">
                                              <div className="card card-flush shadow-none h-100">
                                                  <div className="card-pinned">
                                                      <div className="d-flex justify-content-center">
                                                          <img
                                                              className="card-img"
                                                              src={`https://${
                                                                  host === 'rauprecht-immobilien.de' || host === 'www.rauprecht-immobilien.de' ? 'rauprecht-immobilien.de' : 'mockups.benit.dev'
                                                              }/img/template-pictures/${photo.fileName}`}
                                                              alt="Image Description"
                                                              style={{}}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="card-body">
                                                      <span className="card-subtitle text-body text-center">Fotograf: {photo.photograph}</span>
                                                      <div className="row align-items-center mb-2">
                                                          <div className="col">
                                                              <h4 className="card-title text-inherit text-center">
                                                                  Adobe Stock Nr.:{' '}
                                                                  <a href={photo.adobeLink} target="_blank">
                                                                      {photo.adobeStockNr}
                                                                  </a>
                                                              </h4>
                                                          </div>
                                                          {/* End Col */}
                                                      </div>
                                                      <div className="row align-items-center mb-3 text-center">
                                                          <div className="col">
                                                              <span className="card-subtitle text-body text-center">Lizenz: {photo.license}</span>
                                                          </div>
                                                          {/* End Col */}
                                                      </div>
                                                      {/* End Row */}
                                                  </div>
                                              </div>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                    {/* End Col */}
                </div>
            </div>
            {/* End Team */}
        </>
    );
};

export default SitemapPage;
